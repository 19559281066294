@import 'variables';

@mixin clearfx {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
@mixin vpSize($breakpoint) {
  @if $breakpoint == "xxsm" {
    @media (min-width: 0px) and (max-width: 574px) {
      @content;
    }
  } @else
  if $breakpoint == "x3" {
    @media (max-width: 350px) {
      @content;
    }
  } @else
  if $breakpoint == "mx3" {
    @media (max-width: 350px) {
      @content;
    }
  } @else
  if $breakpoint == "x4" {
    @media (min-width: 400px) {
      @content;
    }
  } @else
  if $breakpoint == "mx4" {
    @media (max-width: 400px) {
      @content;
    }
  } @else
  if $breakpoint == "x5" {
    @media (min-width: 500px) {
      @content;
    }
  } @else
  if $breakpoint == "xsm" {
    @media (min-width: 575px) {
      @content;
    }
  } @else
  if $breakpoint == "mx5" {
    @media (max-width: 500px) {
      @content;
    }
  } @else
  if $breakpoint == "mxsm" {
    @media (max-width: 574px) {
      @content;
    }
  } @else
  if $breakpoint == "smmd" {
    @media (min-width: 575px) and (max-width: 767px) {
      @content;
    }
  } @else
  if $breakpoint == "x6" {
    @media (min-width: 600px) {
      @content;
    }
  } @else
  if $breakpoint == "mx6" {
    @media (max-width: 640px) {
      @content;
    }
  } @else
  if $breakpoint == "x7" {
    @media (min-width: 768px) {
      @content;
    }
  } @else
  if $breakpoint == "mx7" {
    @media (max-width: 767px) {
      @content;
    }
  } @else
  if $breakpoint == "xmd" {
    @media (min-width: 768px) {
      @content;
    }
  } @else
  if $breakpoint == "mxmd" {
    @media (max-width: 768px) {
      @content;
    }
  } @else
  if $breakpoint == "mdlg" {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else
  if $breakpoint == "x8" {
    @media (min-width: 801px) {
      @content;
    }
  } @else
  if $breakpoint == "mx8" {
    @media (max-width: 800px) {
      @content;
    }
  } @else
  if $breakpoint == "x9" {
    @media (min-width: 992px) {
      @content;
    }
  } @else
  if $breakpoint == "mx9" {
    @media (max-width: 991px) {
      @content;
    }
  } @else
  if $breakpoint == "xlg" {
    @media (min-width: 992px) {
      @content;
    }
  } @else
  if $breakpoint == "mxlg" {
    @media (max-width: 992px) {
      @content;
    }
  } @else
  if $breakpoint == "lgxl" {
    @media (min-width: 992px) and (max-width: 1280px) {
      @content;
    }
  } @else
  if $breakpoint == "w0" {
    @media (min-width: 1025px) {
      @content;
    }
  } @else
  if $breakpoint == "mw0" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else
  if $breakpoint == "w1" {
    @media (min-width: 1100px) {
      @content;
    }
  } @else
  if $breakpoint == "mw1" {
    @media (max-width: 1099px) {
      @content;
    }
  } @else
  if $breakpoint == "xxl" {
    @media (min-width: 1200px) {
      @content;
    }
  } @else
  if $breakpoint == "mxl" {
    @media (max-width: 1199px) {
      @content;
    }
  } @else
  if $breakpoint == "w2" {
    @media (min-width: 1281px) {
      @content;
    }
  } @else
  if $breakpoint == "mw2" {
    @media (max-width: 1280px) {
      @content;
    }
  } @else
  if $breakpoint == "w3" {
    @media (min-width: 1367px) {
      @content;
    }
  } @else
  if $breakpoint == "mw3" {
    @media (max-width: 1366px) {
      @content;
    }
  } @else
  if $breakpoint == "w4" {
    @media (min-width: 1441px) {
      @content;
    }
  } @else
  if $breakpoint == "mw4" {
    @media (max-width: 1440px) {
      @content;
    }
  } @else
  if $breakpoint == "w6" {
    @media (min-width: 1681px) {
      @content;
    }
  } @else
  if $breakpoint == "mw6" {
    @media (max-width: 1680px) {
      @content;
    }
  } @else
  if $breakpoint == "w9" {
    @media (min-width: 1921px) {
      @content;
    }
  } @else
  if $breakpoint == "mw9" {
    @media (max-width: 1920px) {
      @content;
    }
  } @else
  if $breakpoint == "k2" {
    @media (min-width: 2561px) {
      @content;
    }
  } @else
  if $breakpoint == "mwk2" {
    @media (max-width: 2560px) {
      @content;
    }
  } @else
  if $breakpoint == "k4" {
    @media (min-width: 3840px) {
      @content;
    }
  } @else
  if $breakpoint == "mwk4" {
    @media (max-width: 3839px) {
      @content;
    }
  }
}
@mixin anchor() {
  a {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}
@mixin input() {
  button,
  input,
  select,
  textarea {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}
