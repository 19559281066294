$theme: #002a6e;
$brown: #a2915b;
// $font: #7f7f7f;
$font: #5f5f5f;
$litefont: #a7a7a7;
$blue: #0d2c5b;
$liteblue: #637792;
$literblue: #aea6cf;
$sky: #30a5da;
$white: #ffffff;
$black: #000000;
$dark: #262626;
$red: #ff0000;
$gray: #f3f7fa;
$darkgray: #617691;
$border: #dadce0;
$warning: #ffc107;
$danger: #f44336;
$success: #4caf50;
$lightpurple: #a58ab6;
$darkgreen: #222b30;
$lightgreen: #5ea7ad;
$lightpink : #e9c9cc;
$green:#8aeb2b;
$darkfont:#080808;
$lightgray:#ebebeb;
$lightwhitegray:#f1f1f1;
$darkgreen:#016736;
