@import 'variables';

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

body{
  .wpforms-container{
    text-align: left;
  }

  div.wpforms-container-full{
    margin-bottom: 0;

    .wpforms-form{
      margin-bottom: 0;

      .wpforms-one-half{
        width: 100%;
        margin-left: 0;
        @include vpSize(xsm){
          width: 48%;
          margin-left: 4%;
        }
      }
      .wpforms-field-label{
        font-weight: 400;
      }
      @extend .field-block;
      @extend .field-check;
      button[type=submit]{
        @extend .btn;
        text-transform: uppercase;
      }
      .wpforms-recaptcha-container{
        padding: 0;
      }
      .grecaptcha-badge{
        z-index: 97;
      }
    }
  }
  span.wpcf7-not-valid-tip{
    font-size: .9rem;
    margin-top: 5px;
  }
  div.wpcf7-response-output {
    text-align: center;
  }
}

.mejs__overlay-button{
  position: relative;
  background-color: rgba(219, 53, 42, 0.4) !important;
  border-radius: 50%;
  background-image: none;
  outline: none;

  &::before{
    width: 60%;
    height: 60%;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(219, 53, 42, 0.8);
    transition: .4s;
  }

  &::after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent $white;
  }

  &:hover{
    &::before{
      animation: rounscale 2s ease-in-out infinite;
    }
  }
}
@keyframes rounscale{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(.9);
  }
  100%{
    transform: scale(1);
  }
}

.pf-control-bar-button g,
.pf-control-bar-pager g{
  fill: $font;
}
#pf-pagerin{
  color: $font;
}

.block-owl-nav,
.block-tiny-nav{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translateY(-50%);
  margin: auto;
  height: 1px;

  button{
    background-color: $white;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    color: $font;
    border: none;
    outline: none;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    transform: translateX(50%);
    transition: .4s;

    &:first-child{
      transform: translateX(-50%);
    }

    svg{
      width: 1rem;
      height: 1rem;
      display: inline-block;
      vertical-align: middle;

      polygon{
        fill: $litefont;
        stroke-width: 1px;
        stroke: $litefont;
        transition: .4s;
      }
    }

    &:hover{
      box-shadow: 0 3px 15px rgba(0,0,0,0.2);

      svg{
        polygon{
          fill: $font;
          stroke: $font;
        }
      }
    }
  }
}
.block-tiny-dots{
  .tns-nav{
    text-align: center;
    margin-top: 2rem;

    button{
      background-color: $border;
      @extend %ButtonReset;
      border-radius: 3px;
      width: 3rem;
      height: 4px;
      margin: 0 5px;
      padding: 0;
      transition: .4s;

      &.tns-nav-active{
        background-color: $red;
      }
    }
  }
}

.sl-overlay{
  background-color: #1e1e1e !important;
  opacity: .9 !important;
}
.sl-wrapper{
  .sl-navigation{
    button{
      color: $font !important;
    }
  }
  .sl-close,
  .sl-counter{
    color: $font !important;
  }
  .sl-image{
    .sl-caption{
      display: none !important;
    }
  }
}
.ngg-navigation{
  a,span{
    font-size: 1.1rem;
    display: inline-block !important;
    vertical-align: middle !important;
    width: 2rem !important;
    height: 2rem !important;
    line-height: 2rem !important;
    line-height: calc(2rem - 2px) !important;
    padding: 0 !important;
    text-align: center !important;
    background-color: transparent !important;
    border: solid 1px $theme !important;
    color: $white !important;
    border-radius: 0 !important;
  }
  span{
    background-color: $theme !important;
  }
}
