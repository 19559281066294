@import 'variables';
.gap {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include vpSize(xsm){
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include vpSize(xmd){
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  @include vpSize(xlg){
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.gap-up {
  padding-top: 2rem;
  @include vpSize(xsm){
    padding-top: 3rem;
  }
  @include vpSize(xmd){
    padding-top: 3.5rem;
  }
  @include vpSize(xlg){
    padding-top: 6rem;
  }
}

.gap-down {
  padding-bottom: 2rem;
  @include vpSize(xsm){
    padding-bottom: 3rem;
  }
  @include vpSize(xmd){
    padding-bottom: 3.5rem;
  }
  @include vpSize(xlg){
    padding-bottom: 6rem;
  }
}
.gap-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include vpSize(xsm){
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @include vpSize(xmd){
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }
  @include vpSize(xlg){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.gap-sm-up {
  padding-top: 1rem;
  @include vpSize(xsm){
    padding-top: 1.5rem;
  }
  @include vpSize(xmd){
    padding-top: 1.7rem;
  }
  @include vpSize(xlg){
    padding-top: 2rem;
  }
}

.gap-sm-down {
  padding-bottom: 1rem;
  @include vpSize(xsm){
    padding-bottom: 1.5rem;
  }
  @include vpSize(xmd){
    padding-bottom: 1.7rem;
  }
  @include vpSize(xlg){
    padding-bottom: 2rem;
  }
}