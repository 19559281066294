@import 'variables';

.btn {
  display: inline-block;
  background-color: transparent;
  color: $black;
  border: solid 1px #7e7e7e;
  border-radius: 0;
  outline: none;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  padding: .5rem 2rem;
  font-size: .9rem;
  font-weight: 500;
  text-shadow: 0 2px 5px rgba(0,0,0,0.2);
  text-align: center;
  margin: 0;
  transition: .4s;
  text-shadow: none;

  &:hover {
    color: $theme;
    background-color: transparent;
    border-width: 1px;
    border-color: $theme;
    text-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  &:hover,
  &:active,
  &:focus{
    outline: none;
    box-shadow: none;
  }
}
.btn-radius{
  border-radius: 4rem;
}
.btn-skewed{
  transform: skew(-10deg);
}
.btn-arrow{
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  color: $white;
  font-size: .9rem;
  font-weight: 600;
  transition:.4s;
  height: 21px;
  line-height: 21px;
  span{
    display: inline-block;
    vertical-align: middle;
  }
  svg{
    height: 1.4rem;
    width: auto;
    display: inline-block;
    margin-left: .7rem;
    fill:$white;
    vertical-align: middle;
    transition:.4s;
  }
  &:hover{
    color: $black;
    svg{
      margin-left: 1.7rem;
      fill:$black;
    }
  }
}
.btn-border{
  color: $white;
  text-transform: uppercase;
  border:1px solid $white;
  padding: .5rem 2rem;
  font-size: .9rem;
  position: relative;
  overflow: hidden;
  transition:.4s;
  display: inline-block;
  &.white-btn{
  color: $white;
  border:1px solid $white;
  &:hover{
  color: $theme;
  border-color: $white!important;
  &:after{
    width: 100%;
    left: 0;
    background-color: $white;
  }
}
  }
&.black-btn{
  color: $black;
  border:1px solid $black; 
  &:hover{
  color: $white;
  border-color: $theme!important;
  &:after{
    width: 100%;
    left: 0;
    background-color: $theme;
  }
}
}
&.span-btn{
  span{
    position: relative;
    z-index: 3;
  }
  &:after{
    z-index: 1;
  }
}
&:after{
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 0%;
  z-index: -1;
  transition:.4s;
}
}
.block-header{
  .btn-border{
    margin-right: 1.5rem;
    font-weight: 300;
    font-size: 1.1rem;
    padding: .5rem 1.5rem;
  }
}
.btn-contact{
  color: $white;
  font-weight: 400;
    span{
      display:inline-block;
      vertical-align: middle;
      font-size: 1rem;
      line-height: .9rem;
       @include vpSize(x7){
        font-size: 1.2rem;
       }
      small{
            font-size: 1.2rem;
    color: #8aeb2b;
    font-weight: 300;
    display: block;
    padding-top: .3rem;
    letter-spacing: 1px;
      }
    }
    svg{
      height: 1.5rem;
      width: 1.5rem;
      @include vpSize(x7){
        height: 1.8rem;
        width: 1.8rem;
      }
      fill:$green;
      display: inline-block;
      vertical-align: middle;
      @include vpSize(x7){
      margin-right: .5rem;
      }
    }
    &:hover{
      color: $white;
    }
}

  .btn-round{
    display: block;
    height: 4rem;
    width: 4rem;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $theme;
    margin: 0rem;
    border-radius: 50%;
    border-style: none;
       box-shadow: 0px 0px 10px 3px #00000059;
       transition:.4a;
       // &:hover{
       //  background-size: 60%;
       // }
       svg{
        height: 1.8rem;
        width: auto;
        position: absolute;
        top: 50%;
        left: 52%;
        transform:translate(-50%,-50%);
      }

  }
.wpforms-submit{
  position: relative!important;
  overflow: hidden!important;
  &:hover{
    color: $white!important;
  }
}




