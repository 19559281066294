@import 'variables';

body {
  margin: 0;
  height: auto !important;
  color: $font;
  // @extend %SailecWeb;
  @extend %Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
 font-family: 'Roboto', sans-serif;

  &.modal-open {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  &.admin-bar {
    .block-header,
    .block-mobile-nav {
      top: 32px;
    }
  }
}

@include anchor() {
  text-decoration: none;
  outline-width: 0;
}
@include input() {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}
.font-poppins{
  font-family: 'Poppins', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: $black;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

.h1,
h1 {
  font-size: 1.9rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  @include vpSize(xsm) {
    font-size: 1rem;
  }
  @include vpSize(xmd) {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  @include vpSize(xlg) {
    font-size: 2.3rem;
  }
}

.h2,
h2 {
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  @include vpSize(xsm) {
    font-size: 2.4rem;
  }
  @include vpSize(xmd) {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
  @include vpSize(xlg) {
    font-size: 3rem;
  }
}

.h3,
h3 {
  font-size: 1.8rem;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  @include vpSize(xsm) {
    font-size: 2rem;
  }
  @include vpSize(xmd) {
    font-size: 2.1rem;
    margin-bottom: 1rem;
  }
  @include vpSize(xlg) {
    font-size: 2.3rem;
  }
}

.h4,
h4 {
  font-size: 1.1rem;
   margin-bottom: 0.5rem;
  @include vpSize(xmd) {
    font-size: 1.2rem;
      margin-bottom: 1rem;
  }
  @include vpSize(xlg) {
    font-size: 1.3rem;
  }
}

.h5,
h5 {
  font-size: 1.1rem;
  @include vpSize(xmd) {
    font-size: 1.1rem;
  }
  @include vpSize(xlg) {
    font-size: 1.1rem;
  }
}

.h6,
h6 {
  font-size: 1rem;
  @include vpSize(xmd) {
    font-size: 1.1rem;
  }
  @include vpSize(xlg) {
    font-size: 1.1rem;
  }
}

a {
  color: $theme;

  &:hover {
    color: inherit;
  }
}

p {
   margin-bottom: 1rem;
    @include vpSize(xmd) {
    margin-bottom: 1.5rem;
  }
}

small {
  font-size: 90%;
  font-weight: inherit;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

label {
  font-weight: 400;
}

button {
  color: inherit;
  font-weight: inherit;
  &:focus{
    outline: none;
  }
}

nav {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.menu-btn-block{
  margin-left: 1rem;
  a{
    font-size: .8rem;
    color: $black;
    &:hover{
      span{
         &:nth-child(1n){
       width: 100%;
      }
      &:nth-child(2n){
        width: 70%;
      }
      }
    }
  }
  .icon{
    width: 30px;
    margin-right: 1rem;
    span{
      height: 2px;
      width: 70%;
      margin: 4px auto;
      background-color: $white;
      display: block;
      transition:.4s;
      &:nth-child(2n){
        width: 100%;
      }
    }
  }
}
.block-mobile-nav {
  background-color: $white;
  color: $black;
  font-weight: 400;
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -260px;
  z-index: 1050;
  transition: 0.4s;
      overflow-y: scroll;
    padding-bottom: 2rem;

  &.active {
    left: 0;
  }

  .data-block {
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 1rem 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  a{
    transition: .4s;
  }

  nav{
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: 0;
        padding: 0.5rem 0;

        a {
          display: block;
          color: $black;
          font-size: .9rem;
          text-transform: uppercase;

          &:hover{
            color: $black;
          }
          &.active{
            color: $black;
          }
        }
      }
    }
  }
}
.mobile-nav-mask{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background-color: $theme;
  opacity: .5;

  &.active{
    transform: translateX(0);
  }
}
.quote-mask{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background-color: $theme;
  opacity: .5;

  &.active{
    transform: translateX(0);
  }
}
.btn-menu{
    position: relative;
    width: 3rem;
    padding: 0;
    cursor: pointer;
    text-align: right;
    background: none;
    @extend %ButtonReset;
    margin-left: 1rem;

    &.active{
      span{
        transform: translateY(-90px);
        opacity: 0;
        &:nth-child(2){
          width: 100%;
          transform: translateY(0);
          opacity: 1;

          &::before{
            transform: rotate(45deg);
          }
          &::after{
            transform: rotate(-45deg);
          }
        }
        &:nth-child(3){
          transform: translateY(90px);
        }
      }
    }

    div{
      width: 1.4rem;
      margin: .5rem auto;
      @include clearfx;
    }

    span{
      display: block;
      float: right;
      position: relative;
      width: 100%;
      height: 3px;
      margin: 2px 0;
      border-radius: 3px;
      background-color: $lightgreen;
      transition: .4s;

      &:nth-child(2){
        background-color: transparent;

        &::before, &::after{
          width: 100%;
          height: 100%;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color:$lightgreen;
          transition: .4s;
        }
      }
    }
}
.close {
  position: relative;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 99;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: $theme;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}



.block-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: .8rem;
     padding-bottom: .8rem;
  z-index: 999;
  transition:.4s;
  background-color: $theme;
  &.fixed-header{
    @include vpSize(x9){
    padding-top: .8rem;
     padding-bottom: .8rem;
    }
     // .block-logo{
     //  width: 90px;
     //  @include vpSize(xsm){
     //     width: 110px;
     //  }
     //   @include vpSize(x9){
     //     width: 130px;
     //   }
     // }
  }
}
.block-logo{
  width: 110px;
  @include vpSize(xsm){
    width: 140px;
  }
  @include vpSize(x7){
    width: 200px;
  }
  transition:.4s;
  a{
    display: block;
    img{
     width: 100%;
     height: auto;
     margin-bottom: -8px;
    }
  }
}
.block-top-nav{
  ul{
    padding: 0;
    margin:0;
    list-style: none;
    li{
      margin:0;
      padding-left: 0;
      margin-right: .9rem;
      @include vpSize(w1){
        margin-right: 1rem;
        font-size: 1.1rem;
      }
      a{
        color: $white;
        font-weight: 300;
      }
      &.current-menu-item{
        a{
          font-weight: 500;
        }
      }
    }
  }
}
.block-banner{
  position: relative;
  .owl-dots{
    position: absolute;
    left: 50%;
    transform:translateX(-50%);
    bottom: 20px;
    z-index: 10;
    width: 100%;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1200px;
    @include vpSize(x9){
      bottom: 240px;
    }
     @include vpSize(w1){
      bottom: 220px;
    }
     // @include vpSize(xsm){
     //     max-width: 540px;
     //   }
     // @include vpSize(x7){
     //     max-width: 720px;
     //   }
     // @include vpSize(x9){
     //     max-width: 960px;
     //   }
     // @include vpSize(w2){
     //     max-width: 1140px;
     //   }
    .owl-dot{
      span{
        height: 5px;
        width: 1.4rem;
        border:1px solid $white;
        display: block;
        margin: 3px;
      }
      &.active{
        span{
          background-color: $white;
        }
      }
    }
  }
}
.banner-block{
  position: relative;
    .videolink{
      position: absolute;
      top: 80%;
      left: 20%;
      transform: translate(-50%,-50%);
      z-index: 20;
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
      border: 1px solid $white;
       @include vpSize(xsm){
      top: 70%;
       left: 50%;
       height: 4rem;
      width: 4rem;
    }

       @include vpSize(x7){
      top: 60%;
      height: 5rem;
      width: 5rem;
    }
       @include vpSize(x9){
      top: 50%;
    }
      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-39%,-50%);
        height: 2rem;
        width: 2rem;
        fill:$white;
         @include vpSize(xsm){
       height: 2.5rem;
        width: 2.5rem;
    }

       @include vpSize(x7){
      height: 3rem;
        width: 3rem;
    }
      }
  }
  .video-block {
      position: relative;
      z-index: 0;
      height: 100%;
      height: 500px;
      @include vpSize(xsm){
          height: 700px;
      }
      @include vpSize(x7){
        height: 100vh;
      }

      &:after{
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $black;
        opacity: .6;
        z-index: 2;
      }

      video {
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 0;
          -o-object-fit: fill;
          object-fit: fill;
          transform: translateX(-50%) translateY(-50%);
      }
  }
  figure{
    position: relative;
    margin: 0;
    z-index: 1;
    height: 500px;
    @include vpSize(xsm){
        height: 700px;
    }
    @include vpSize(x7){
      height: 100vh;
    }
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $black;
      opacity: .6;
      z-index: 2;
    }
    img{
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit:cover;
      z-index: 1;
    }
  }
  .banner-caption{
    position: absolute;
    top: 22%;
    left: 0;
    color: $white;
    z-index: 5;
    width: 100%;
     @include vpSize(xsm){
     top: 30%;
    }
    @include vpSize(x9){
     top: 20%;
    }
     @include vpSize(w1){
     top: 26%;
    }
    h2, h1{
      font-weight: 700;
      font-size: 2rem;
      line-height: 1;
      color: $white;
       @include vpSize(xsm){
      font-size: 2.5rem;
    }
      @include vpSize(x9){
      font-size: 3rem;
    }
    }
    p{
      margin-bottom: 2rem;
    }
  }
}
.banner-bottom-boxes{
  position: relative;
  width: 100%;
  .container-fluid{
    max-width: 1200px;
    padding: 0;
    @include vpSize(w2){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
   @include vpSize(x9){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
   }
}
.banner-bottom-box{
  position: relative;
   @include vpSize(xsm){
     padding-bottom: 70%;
        }
         @include vpSize(x6){
     padding-bottom: 55%;
        }
   @include vpSize(x7){
     padding-bottom: 120%;
        }
   @include vpSize(x8){
 padding-bottom: 100%;
  }
  @include vpSize(x9){
 padding-bottom: 90%;
  }
  @include vpSize(w1){
 padding-bottom: 70%;
  }
  figure{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin:0;
    overflow: hidden;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: -3%;
      left: 0;
      height: 106%;
      width: 100%;
      background-color: $black;
      opacity: .7;
      z-index: 2;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      z-index: 1;
    }
  }
  .content{
    z-index: 3;
    padding: 2rem 1.8rem;
    position: relative;
    color: $white;
     @include vpSize(xsm){
     position: absolute;
    left: 0;
    top: 0;
    font-size: .9rem;
    width: 100%;
        }
     @include vpSize(x7){
  padding: 1rem .8rem;
  }
  @include vpSize(x9){
 padding: 2rem 1.8rem;
  }
    .title{
      font-size:1.1rem;
      font-weight: 600;
      color: $white;
      @include vpSize(w1){
        font-size:1.16rem;
      }
    }
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }

 }
   &.big-content{
    background-color: $theme;
    text-align: center;
    .content{
      @include vpSize(xsm){
       top: 50%;
      transform:translateY(-50%);
        }
    .title{
      font-size: 6.8rem;
      line-height: 6rem;
      font-weight: 800;
      margin:0;
      @include vpSize(x7){
       font-size: 5.5rem;
        }
       @include vpSize(x9){
       font-size: 6.8rem;
        }
        @include vpSize(w1){
       font-size: 7.8rem;
        }

    }
    font-size: 1.5rem;
    p{
      margin:0;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 100;
    }
    }
  }
}
.block-hm-about{
  figure{
    position: relative;
    width: 100%;
    padding-bottom: 125%;
    @include vpSize(x9){
       margin-top: 5rem;
        }
    margin-bottom:0;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      object-position:left 15% top 0%;
    }
  }
  .content{
    padding-top: 2rem;
     @include vpSize(x9){
       padding: 0;
        }
    .pre-title{
      font-size: 1.3rem;
      height: 1.3rem;
      color: $black;
      margin:0;
    }
    .title{
      font-size: 2.5rem;
      color: $theme;
      font-weight: 700;
      line-height: 1;
      @include vpSize(xsm){
       font-size: 3.2rem;
        }
       @include vpSize(x7){
       font-size: 3.5rem;
        }
       @include vpSize(x9){
       font-size: 3.7rem;
        }
        @include vpSize(w1){
       font-size: 4rem;
        }
    }
    .btn-border{
      margin-top: 1rem;
      display: inline-block;
    }
  }
}
.home-service{
  position: relative;
  padding-bottom: 140%;
  opacity: .5;
  @include vpSize(xsm){
     opacity: 1;
        }
  figure{
    position: absolute;
    top: 0;
    left: 0;
    margin:0;
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 0;
    transition:.4s;
    box-shadow: 0px 10px 10px 2px #0000007a;
    @include vpSize(xsm){
    box-shadow: 0px 15px 15px 3px #0000007a;
  }

    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      margin:0;
      height: 100%;
      width: 100%;
      z-index: 3;
      background-color: $black;
      opacity: .5;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      z-index: 1;
    }
  }
  .content{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem 1.5rem;
    width: 100%;
    z-index: 5;
    .title{
      font-size: .9rem;
      height: 7rem;
      position: relative;
      color: $theme;
      font-weight: 600;
      @include vpSize(x4){
       font-size: 1rem;
      height: 6.5rem;
        }
      @include vpSize(xsm){
       font-size: 1rem;
      height: 6.5rem;

        }
      @include vpSize(x7){
       font-size: 1rem;
      height: 6.5rem;
        }
       @include vpSize(x9){
       font-size: 1rem;
      height: 6rem;
        }
       @include vpSize(w0){
       font-size: 1rem;
      height: 6.5rem;
        }
       @include vpSize(w2){
       font-size: 1rem;
      height: 5.5rem;
        }
      &:after{
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
         background-color: $theme;

      }
    }
    a{
      display: flex;

      span{
        font-size: .8rem;
          color: $theme;
         transition:.4s;
         @include vpSize(x4){
       font-size: .9rem;
        }
      }
      img{
        width: 20px;
        transition:.4s;
         @include vpSize(x4){
      width: 25px;
        }
        @include vpSize(xsm){
         width: 30px;
        }

      }

    }
  }
  &:hover{
    figure{
      opacity: 1;
    }
    .content{
      .title{
        color: $white;
        &:after{
          background-color: $white;
        }
      }
      a{
        span{
          color: $white;
        }
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.block-home-services{
  margin-right: -8%;
  margin-left: -50%;
  position: relative;
   @include vpSize(xsm){
     margin-right: -8%;
  margin-left: -30px;
        }
  .owl-stage-outer{
    padding-left: 30px;
    padding-right: 30px;
  }
  &:after{
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 45.5%;
    background-color: $white;
    opacity: 0;
    z-index: 5;
    @include vpSize(xsm){
       width: 30.5%;
       display: block;
        }
    @include vpSize(x7){
       width: 28.5%;
        }
     @include vpSize(w0){
       width: 17.5%;
        }
  }
  &:before{
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 36%;
    background-color: $white;
    opacity: .7;
    z-index: 5;
     @include vpSize(xsm){
       width: 24.5%;
       display: block;
        }
        @include vpSize(x7){
       width: 26%;
        }
    @include vpSize(x9){
       width: 19%;
        }
     @include vpSize(w0){
        width: 16%;
        }
  }
}
.js-owl-services-nav{
  .owl-prev{
    display: none;
  }
  .owl-next{
    height: 4rem;
    width: 4rem;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $theme;
    margin: 0rem;
    border-radius: 50%;
    position: absolute;
    top: 63%;
    right: 9%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 6;
    border-style: none;
      box-shadow: 0px 0px 10px 3px #00000059;
      svg{
        height: 1.8rem;
        width: auto;
        position: absolute;
        top: 50%;
        left: 52%;
        transform:translate(-50%,-50%);
      }

  }
}
.home-txt-btn{
  padding-right: 6rem;
  position: relative;
  margin-top: 2rem;
  @include vpSize(x9){
    margin-top: 0;
  }
  .title{
    font-size: 1.5rem;
    font-weight: 600;
  }
  .btn-round{
    position: absolute;
    right: 0;
    top: 50%;
    transform:translateY(-50%);
  }
}
  figure.background{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    margin:0;
    img{
       position: absolute;
       top: 0;
       left: 0;
       height: 100%;
       width: 100%;
       z-index: -1;
       object-fit:cover;
    }
    &:after{
      content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $black;
    opacity: .6;
    z-index:0;
    }
  }
.block-testimonials-hm{
  position: relative;
  z-index: 0;

  .video-block{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    margin: 0;
    overflow: hidden;

    &:after{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $black;
      opacity: .6;
      z-index: 2;
    }

    video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 0;
        -o-object-fit: fill;
        object-fit: fill;
        transform: translateX(-50%) translateY(-50%);
    }
  }
  .content{
    max-width: 700px;
    position: relative;
    z-index: 5;
    text-align: center;
    margin:0 auto;
    line-height: 1.2;
    color:
    $white;
    .title{
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.9rem;
      margin-bottom: 3rem;
    }

    figure{
      width: 4rem;
      position: relative;
      padding-bottom: 5rem;
      margin-bottom: .5rem;
      margin-left: auto;
      margin-right: auto;
      display: block;
      img{
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         object-fit:cover;
      }
    }
    .name{
      font-size: .9rem;
      color: $white;
      font-weight: 600;
      margin-bottom: 1.5rem;
      line-height: .9rem;
    }
    .place{
      font-size: .8rem;
    }
  }
}
.cr-logo-block{
  display: inline-block;
  height: auto;
  margin:0 3.5rem;
  img{
    width: 100%;
    height: auto;
    width: auto;
    max-width: 7rem;
    max-height: 6rem;
  }
}
.block-home-quote{
  text-align: center;
  position: relative;
  .back-img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding-bottom: 70%;
    min-height: 700px;
  }
  .content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
      .title{
    font-size: 1.9rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    font-weight: 500;
      }
      p{
        margin-bottom: 3rem;
      }
      .btn-border{
        border: 2px solid $black;
      }
  }
}
.footer-caption{
  .title{
    text-transform: uppercase;
    font-size: 1.1rem;
    color: $theme;
    margin-bottom: 1.5rem;
     @include vpSize(xsm){
      font-size: 1.25rem;
      margin-bottom: 1.8rem;
        }
        @include vpSize(x7){
     font-size: 1.5rem;
     margin-bottom: 3rem;
        }
  }
}
.footer-menu{
  font-size: .9rem;
  ul{
    list-style: none;
    margin:0;
    padding: 0;
    li{
      width: 49%;
      display: inline-block;
      padding: 0 0 1rem;
      a{
        color: $darkfont;
        transition:.4s;
        &:hover{
          color: $theme;
        }
      }
    }
  }
}
.footer-nav{
  font-size: .9rem;
   color: $darkfont;
  ul{
    list-style: none;
    margin:0;
    padding: 0;
    li{
     padding: 0 0 1rem;
     a{
        color: $darkfont;
         transition:.4s;
        &:hover{
          color: $theme;
        }
      }
    }
  }
}
.footer-bottom{
  border-top: 1px solid $lightgray;
  padding-top: 1.5rem;
}
.footer-social{
  ul{
    list-style: none;
    margin:0;
    padding: 0;
    li{
      padding: 0;
      padding-right: 1rem;
      a{
        font-size: 1.5rem;
        color: $theme;
      }
    }
  }
}
.copy-right{
  font-size: .9rem;
  color: $darkfont;
  a{
    display: inline-block;
  img{
    height: 1em;
  }
}
}
.column-block{
  margin-left: -3px;
  margin-right: -3px;
  &:after{
    display: block;
    content: "";
    clear: both;
  }
  .column{
    float: left;
    &:after{
      display: block;
      content: "";
      clear: both;
    }
    .photo{
      padding: 3px;
      figure{
        position: relative;
        width:100%;
        margin: 0;
        img{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit:cover;
        }
      }
    }
  }
  .column-01{
    width: 100%;
    @include vpSize(xsm){
      width: 40%;
    }
    @include vpSize(x9){
      width: 21%;
    }
    .photo-1{
      width: 50%;
      float: right;
       @include vpSize(xsm){
      width: 100%;
    }
       @include vpSize(x9){
       padding-top: 92%;
    }
      figure{
        padding-bottom: 92%;
         @include vpSize(x9){
       padding-bottom: 140%;
    }
      }
    }
     .photo-2{
       width: 50%;
       @include vpSize(xsm){
      width: 100%;
    }
      float: right;
       @include vpSize(x9){
        width: 55%;
       }
      figure{
        padding-bottom: 92%;
        @include vpSize(xsm){
       padding-bottom: 59%;
    }
        @include vpSize(x9){
       padding-bottom: 110%;
       }
      }
    }
  }
  .column-02{
    width: 100%;
     @include vpSize(xsm){
     width: 60%;
    }
     .photo-2{
      width: 45%;
      float: right;
      figure{
        padding-bottom: 61.6%;
      }
    }
     .photo-1{
      width: 55%;
      float: right;
      figure{
           padding-bottom: 50%;
      }
    }
     .photo-3{
      width: 100%;
      float: right;
      figure{
        padding-bottom: 73.1%;
      }
    }
  }
  .column-03{
    width: 50%;
     @include vpSize(x9){
        width: 19%;
       }
      .photo-2{
      width: 100%;
      float: left;
      figure{
        padding-bottom: 70%;
         @include vpSize(x9){
          padding-bottom: 169.5%;
         }
      }
    }
     .photo-1{
      float: left;
      width: 100%;
      @include vpSize(x9){
        padding-top: 94%;
        width: 55%;
      }
      figure{
        padding-bottom: 50%;
        @include vpSize(x9){
        padding-bottom: 110%;
      }
      }
    }
  }
  .column-04{
    width: 50%;
    @include vpSize(x9){
      width: 100%;
    }
     .photo-1{
      width: 100%;
      float: right;
      @include vpSize(x9){
       width: 21%;
    }
      figure{
        padding-bottom: 60%;
      }
    }
     .photo-2{
      width: 100%;
      float: right;
       @include vpSize(x9){
         width: 10.2%;
       }
      figure{
        padding-bottom: 60%;
         @include vpSize(x9){
         padding-bottom: 100%;
       }
      }
    }
  }
  .column-05{
    width: 100%;
    @include vpSize(x9){
      width: 38.5%;
    }
     .photo-1{
      width: 100%;
      float: right;
      @include vpSize(x7){
         width: 50%;
      }
      @include vpSize(x9){
       width: 100%;
       float: none;
      }
      figure{
           padding-bottom: 100%;
            @include vpSize(x9){
              padding-bottom: 70%;
            }
      }
    }
    .photo-2{
      width: 100%;
      float: right;
      @include vpSize(x7){
      width: 50%;
      }
      @include vpSize(x9){
       width: 100%;
       float: none;
      }
      .text-block{
        width: 95%;
        margin-top: 1rem;
         @include vpSize(x7){
       padding-top: 0rem;
      }
         @include vpSize(x9){
       padding-top: 6rem;
      }
      }
    }
  }
  .column-06{
    width: 50%;
    @include vpSize(x9){
      width: 31%;
    }
     .photo-1{
      width: 100%;
      float: right;
      figure{
        padding-bottom: 87.5%;
      }
    }
     .photo-2{
      width: 100%;
      float: right;
      figure{
        padding-bottom: 124%;
         @include vpSize(x9){
          padding-bottom: 120%;
         }
      }
    }
  }
  .column-07{
     width: 50%;
    @include vpSize(x9){
       width: 30.5%;
    }
    .photo-2{
      width: 100%;
      figure{
        padding-bottom: 71%;
      }
    }
     .photo-1{
      width: 100%;
      figure{
           padding-bottom: 72%;
      }
    }
     .photo-3{
      width: 100%;
      figure{
        padding-bottom: 66%;
      }
    }
  }
}
.page-banner{
  position: relative;
  padding-bottom: 35%;
  min-height: 400px;
  figure{
    margin:0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $black;
      opacity: .7;
      z-index: 2;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      z-index: 1;
    }
  }

  .content{
    position: absolute;top: 60%;
    left: 0;
    transform:translateY(-50%);
    z-index: 3;
    text-align: center;
    width: 100%;
    font-size: 1rem;
    font-weight: 100;

     @include vpSize(xsm){
       font-size: 1.1rem;
        }
        @include vpSize(x7){
        font-size: 1.2rem;
        }
    @include vpSize(x9){
        font-size: 1.4rem;
        }
     @include vpSize(w0){
        font-size: 1.6rem;
        }
    h2, h1{
      color: $white;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 2.3rem;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 1rem;

     @include vpSize(xsm){
         font-size: 2.5rem;
      line-height: 2.7rem;
        }
        @include vpSize(x7){
         font-size: 2.8rem;
      line-height: 2.5rem;
        }
    @include vpSize(x9){
        font-size: 3rem;
      line-height: 2.7rem;
        }
     @include vpSize(w0){
       font-size: 3.5rem;
      line-height: 3rem;
        }
    }
    p{
      color: $white;
      text-transform: uppercase;
      line-height: 1rem;
     @include vpSize(xsm){
      line-height:  1.1rem;
        }
        @include vpSize(x7){
        line-height:  1.2rem;
        }
    @include vpSize(x9){
       line-height:  1.4rem;
        }
     @include vpSize(w0){
       line-height:  1.6rem;
        }
    }
  }
}
.page-block{
    ul{
  list-style: none;
  padding: 0;
  &:last-child{
    margin-bottom: 0;
  }
  li{
    position: relative;
    padding-left: 2rem;
    padding-top: .5rem;
    padding-bottom: .55rem;
    font-family: 'Poppins', sans-serif;
    color: $black;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: .7rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border:1px solid $green;
    }
      &:before{
      display: block;
      content: "";
      position: absolute;
      top: .95rem;
      left: .25rem;
      height: .5rem;
      width: .5rem;
      background-color: $green;
      border-radius: 50%;
      z-index: 5;
    }
  }
}
.pre-title{
  margin:0;
}
.title{
  text-transform: uppercase;
}
h2, h3{
  color: $theme;
  margin-bottom: 2rem;
}
&.block-welcome{
  .text-block{
    max-width: 800px;
    margin:0 auto;
  }
}
}
.block-len{
  .items{
    position: relative;
    &:after{
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background-color: $lightwhitegray;
      z-index: -1;
    }
  }
  .item{
    position: relative;
    padding-bottom: 3rem;
  }
  .pre-title{
    font-size: 2rem;
    color: $theme;
    font-weight: 200;
    padding: 2rem 5%;
    position: relative;
    overflow: hidden;
    //  @include vpSize(x4){
    //      bottom: 41.5%;
    // left: -75px;
    //     }
    //  @include vpSize(xsm){
    //      bottom: 33.5%;
    // left: -13%;
    //     }
    //     @include vpSize(x7){
    //         bottom: 48%;
    //        left: -23%;
    //     }
    @include vpSize(x9){
      overflow: visible;
      padding: 2rem 1rem;
           bottom: 34%;
           left: -14%;
           position: absolute;
           transform: rotate(-90deg);
           padding: 0;
        }
      @include vpSize(w0){
           bottom: 34.5%;
           left: -14%;
        }
     @include vpSize(xxl){
       bottom: 28%;
       left: -11%;
        }
    &:after{
      display: block;
      content: "";
      position: absolute;
      width: 200px;
      height: 2px;
      background-color: $theme;
      top: 50%;
      left: 260px;
        @include vpSize(x9){
          left: 108%;
        }
    }
  }
  figure{
    margin:0;
    width: 90%;
    margin-left: 5%;
    position: relative;
    padding-bottom: 85%;
     @include vpSize(x9){
      margin-top: -5rem;
      width: 80%;
      margin-left: 15%;
     }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
  }
  .text-block{
    padding: 1rem 5%;
    @include vpSize(x9){
      padding-right: 3rem;
    padding-left: 1rem;
        }
    p{
      &:last-child{
        margin:0;
      }
    }
  }
}
.repeater-block01{
  padding: 1rem 0;
   @include vpSize(xsm){
       padding: 2rem 0;
        }
  &:nth-child(2n){
    flex-direction: row-reverse;
  }
  figure{
    margin:0;
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
  }
  .text-block{
    padding: 2rem 0;
    p{
    &:last-child{
      margin: 0;
    }
  }
    h2,h3,h4,h5{
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
.repeater-block02{
  padding: 1.5rem 0;
   @include vpSize(x7){
      padding: 2rem 0;
    }
  @include vpSize(x9){
      padding: 3rem 0;
    }

  &:nth-child(2n){
    flex-direction: row-reverse;
    figure{
      &:after{
        right: unset;
        left: -1.5rem;
      }
    }
  }
  figure{
    margin:0;
    width: 100%;
    padding-bottom: 80%;
    position: relative;
    &:after{
      display:block;
      content: "";
      position: absolute;
      top: -1.5rem;
      right: -1.5rem;
      width: 40%;
      height: 40%;
      z-index: -1;
      background-color: $lightgray;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
  }
  .text-block{
    padding-bottom: 2rem;
    @include vpSize(x9){
      padding: 0;
    }
    p{
    &:last-child{
      margin: 0;
    }
  }
    h2,h3,h4,h5{
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
.repeater-block-03{
  padding-top: 1rem;
  padding-bottom: 1rem;
   h2,h3,h4,h5{
      text-transform: uppercase;
      font-weight: 600;
    }
  figure{
    margin:0;
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    margin-bottom: 2rem;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
  }
}
.repeater-block-04{
  &:nth-child(3){
    .items{
      flex-direction: row-reverse;
      @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
  }
}
   &:nth-child(4){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
    &:nth-child(7){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
  }
}
   &:nth-child(8){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(11){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(12){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(15){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(16){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(19){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
   &:nth-child(20){
    .items{
      flex-direction: row-reverse;
        @include vpSize(x7){
      figure{
        margin-left: auto;
      }
      .text-block{
        padding-left: 2rem;
        padding-right: 0;
      }
      .title{
        left: auto;
        right: 0;
      }

    }
    }
  }
.items{
  background-color: $lightgray;
}
  margin:1rem 0;
  .item{
    position: relative;
  }
  figure{
    margin:0;
    padding-bottom: 60%;

    @include vpSize(x7){
        padding-bottom: 80%;
        width: 92%;
     }
    @include vpSize(x9){
       padding-bottom: 170%;
     }
     @include vpSize(w0){
       padding-bottom: 170%;
     }
     @include vpSize(w1){
       padding-bottom: 165%;
     }
     @include vpSize(w2){
       padding-bottom: 120%;
     }

    position: relative;
     &:after{
      display:block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $black;
      opacity: .5;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      z-index: 0;
  }
}
.title{
  position: absolute;
  padding: 1.5rem;
 bottom: 0;
  left: 0;
 width: 100%;
  color: $white;
  z-index: 3;
  font-size: 1.3rem;
  margin: 0;
  line-height: 1.3;
  font-weight: 600;
  @include vpSize(x7){
     width: 90%;
  }
}
.text-block{
  padding: 1.5rem;
  font-size: .9rem;
   @include vpSize(x7){
      padding: 1.5rem;
      padding-left: 0;
   }
  a{
    font-size: .8rem;
    font-weight: 500;
  }
}
}
.map-block{
    width: 100%;
    padding-bottom: 100%;
    position: relative;
        &:after{
      display:block;
      content: "";
      position: absolute;
      bottom: -1.5rem;
      left: -1.5rem;
      width: 70%;
      height: 60%;
      z-index: -1;
      background-color: $theme;
    }
  .map-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.map-block-footer{
    width: 100%;
    padding-bottom: 50%;
    position: relative;
        &:after{
      display:block;
      content: "";
      position: absolute;
      bottom: -1.5rem;
      left: -1.5rem;
      width: 70%;
      height: 60%;
      z-index: -1;
      background-color: $theme;
    }
  .map-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.block-map{
  padding-top: 3rem;
   @include vpSize(x7){
  padding-top: 4rem;
}
   @include vpSize(x9){
    padding-left: 15%;
    padding-top: 0;
   }
  .title{
    color: $theme;
    text-transform: uppercase;
    font-weight: 500;
  }
}
.block-form{
 padding: 5% 0;
  @include vpSize(x9){
    padding: 0;
 padding-right: 17%;
  }
}
.block-icon-box{
  padding: 1.5rem;
  position: relative;
  z-index: 3;
  .title{
    font-size: 1rem;
    color: $theme;
    line-height: 1rem;
    font-weight: 600;
  }
  .icon{
    width: 2.5rem;
    height: 2.5rem;
    svg{
      width: 100%;
      height: auto;
    }
    &.contact{
     width:2rem;
    }
  }
  .content{
    padding-top: 1.5rem;
    font-size: .9rem;
    p{
      margin-bottom: .5rem;
      a{
        color: $font;
        transition:.4s;
        &:hover{
          color: $theme;
        }
      }
    }
  }
}
.block-icon-box-holder{
  position: relative;
  margin-bottom: 1rem;
  max-width: 400px;
  &:after{
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    background-color: $white;
    z-index: 0;
  }
}
.widget_categories{
  h2{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  ul{
  list-style: none;
  padding: 0;
  li{
    position: relative;
    padding-left: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    color: $black;
    text-transform: uppercase;
    font-weight: 600;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: .5rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border:1px solid $green;
    }
      &:before{
      display: block;
      content: "";
      position: absolute;
      top: .75rem;
      left: .25rem;
      height: .5rem;
      width: .5rem;
      background-color: $green;
      border-radius: 50%;
      z-index: 5;
    }
  }
}
}
// .widget_search{
//   .screen-reader-text{
//     display: none;
//   }
//   #searchform{
//     div{
//     border:1px solid $theme;
//     display: flex;
//     position: relative;
//      &:after{
//           position: absolute;
//           content: "\f002";
//           font-family: FontAwesome;
//           display: block;
//           top: 0;
//           right: 0;
//           height: 100%;
//           width: 50px;
//           text-align: center;
//           line-height: 50px;
//           color: $theme;
//           z-index: -1;
//         }
//
//          &:before{
//           display: block;
//           content: "Search Here...";
//           position:absolute;
//           top: 50%;
//           left: 1rem;
//           transform:translateY(-50%);
//           z-index:-1;
//           font-weight: 300;
//         }
//     }
//     input{
//       height: 50px;
//       border: none;
//       background-color: transparent;
//       &:focus{
//         background-color: #fff;
//       }
//       &:active{
//         background-color: #fff;
//       }
//       &#s{
//         width: calc( 100% - 50px);
//         padding-left: 10px;
//         position: relative;
//
//       }
//       &#searchsubmit{
//         width: 50px;
//         overflow: hidden;
//         background-color: transparent;
//         color: transparent;
//         position: relative;
//
//       }
//     }
//   }
// }
// .widget_is_widget{
//   .screen-reader-text{
//     display: none;
//   }
//   .is-search-form{
//
//     border:1px solid $theme;
//     display: flex;
//     position: relative;
//      &:after{
//           position: absolute;
//           content: "\f002";
//           font-family: FontAwesome;
//           display: block;
//           top: 0;
//           right: 0;
//           height: 100%;
//           width: 50px;
//           text-align: center;
//           line-height: 50px;
//           color: $theme;
//           z-index: -1;
//         }
//         label{
//         width: calc( 100% - 50px);
//         padding-left: 10px;
//         margin: 0;
//         input{
//           width: 100%;
//         }
//         }
//
//     button, input{
//       height: 50px;
//       border: none;
//
//       &.is-search-submit{
//         width: 50px;
//         overflow: hidden;
//         background-color: transparent;
//         color: transparent;
//         position: relative;
//
//       }
//     }
//   }
// }
.recent-posts-widget-with-thumbnails{
  padding-top: 3rem;
  h2{
    font-size: 1.3rem;
    text-transform: uppercase;
  }
  a{
    span{
      font-size: 1.1rem;
      font-weight: 600;
      color: $theme;
    }
  }
}
.block-featured{
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  margin-bottom: 2rem;
  img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
  }
}
.block-single-blog{
  .block-title{
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.service-area-block{
  ul{
  list-style: none;
  padding: 0;
  li{
    position: relative;
    padding-left: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    color: $black;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: .5rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border:1px solid $green;
    }
      &:before{
      display: block;
      content: "";
      position: absolute;
      top: .75rem;
      left: .25rem;
      height: .5rem;
      width: .5rem;
      background-color: $green;
      border-radius: 50%;
      z-index: 5;
    }
  }
}
}
.service-home-hold{
  overflow: hidden;
}
#menu-top-menu-1{
.menu-item{
  position: relative;
  .sub-menu{
    position: absolute;
    display: none;
    width: 350px;
    background-color: $white;
    // padding: .5rem 0 !important;
    display: none !important;
    left: -27px;
    top: 45px;
    background-color: $theme;
    padding: 1rem !important;
    li{
      display: block;
      padding: .3rem 1.5rem !important;
      line-height: 1.2;
      position: relative;
      z-index: 5;
      margin: 0 !important;
       position: relative;
       padding-bottom: 35px !important;
       &.current-menu-item{
      a{
        font-weight: 500;
      }
    }
    a{
      position: absolute;
      width: 82%;
      top: 50%;
      right: 0;
      transform:translatey(-50%);
      font-weight: 300;
      color: $white;
      font-size: 1.1rem;
    }
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20%;
      background-image: url(../images/weapon.png);
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
    }
      a{
        color: $white;
       &:hover{
        color: $white;
       }

      }
    }
  }
  &:hover{
    .sub-menu{
      display: block !important;
    }
  }
}
}
.block-mobile-nav{
  .sub-menu{
    display: none;
    padding-left: 1rem;
  }
  .child-toggler{
    position: absolute;
    right: 0;
    padding: 8px;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 5;
    top: -9px;
    font-size: 1.5rem;
    color: $theme;
    &.active{
          transform: rotate(90deg);
    }
    a{
    color: #fff;
    height: 40px;
    line-height: 40px;
    display: block;
    -webkit-transition: .4s;
    transition: .4s;
    position: relative;
    z-index: 1;
    }
  }
}
.js-owl-testimonials-nav{
  button{
    background-color: transparent;
    height: 2rem;
    width: 2rem;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #fff;
    margin: 0 .5rem;
    position: relative;
    svg{
      position: absolute;
      height: 1rem;
      width: auto;
      top: 50%;
      left: 50%;
      transform:translate(-50%, -50%);
    }
    &.owl-prev{
      svg{
        left: 45%;
      }
    }
     &.owl-next{
      svg{
        left: 54%;
      }
    }
  }
}
.btn-contact:hover svg{
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: rotate(0deg);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% {
    transform: rotate(-5deg);
  }

  20%, 80% {
    transform: rotate(5deg);
  }

  30%, 50%, 70% {
    transform: rotate(-10deg);
  }

  40%, 60% {
    transform: rotate(10deg);
  }
}
.footer-social ul li a:hover i{
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: rotate(0deg);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes downarrow {
  0% { transform: translateX(-100px); opacity: 0; }
  100% { transform: translateX(0px); opacity: 1; }
}
// .btn-round:hover{
//   animation: downarrow 0.82s;
// }
// .current-menu-item{
//   a{
//     font-weight: 700;
//   }
// }
.home-service .content a:hover{
  img{
    animation: downarrow .4s;
  }
}
.blog-box .btn-arrow:hover{
  img{
    animation: downarrow .4s;
  }
}
.block-welcome{
  overflow: hidden;
}
.is--modal{
  overflow: hidden;
}
.dsk-nav{
  ul{
    li{
      position: relative;
      &:after{
        display: block;
        content: "";
        position: absolute;
        height: 2rem;
        width: 1rem;
        background-image: url(../images/leaf.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        top: -280%;
        left: -8px;
        transition:.4s;
        z-index: -1;
      }
      &.current-menu-item{
        &:after{
         top: -6px;
        }
      }
      &.current-menu-parent{
        &:after{
         top: -6px;
        }
      }
      .sub-menu{
        li{
          &:after{
            display: none;
          }
        }
      }
    }
  }
}
.blog-box{
  p{
    margin-bottom: .5rem;
  }
  .btn-arrow{
    width: 180px;
    transition:.4s;
    span{
      color: $theme;
    }
    img{
      width: 30px;
      margin-top: 3px;
    }
    &:hover{
      width: 200px;
    }
  }
}
.block-home-services{
  .owl-carousel{
    .owl-stage-outer{
      .owl-stage{
        div.center{
          .home-service{
            opacity: 1;
          }
          figure{
            opacity: 1;
          }
             .content{
      .title{
        color: $white;
        &:after{
          background-color: $white;
        }
      }
      a{
        span{
          color: $white;
        }
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
        }
      }
    }
  }
}
.block-home-gallery{
  overflow: hidden;
}
.about-gallery{
  overflow: hidden;
}
.service-area-gallery{
  margin-top: 1.5rem;
  &:after{
    display: block;
    content: "";
    clear: both;
  }
  figure{
    position: relative;
    padding-bottom: 50%;
    width: calc(40% - 6px);
    margin-left: 3px;
    margin-right: 3px;
    float: right;
    margin-bottom: 6px;
    &:nth-child(2){
      width: calc(60% - 6px);
    }
    &:nth-child(3){
      width: calc(60% - 6px);
    }
    &:nth-child(6){
      width: calc(60% - 6px);
    }
    &:nth-child(7){
      width: calc(60% - 6px);
    }
    a{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }
    img{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit:cover;
    }
  }
}
.mx-230{
  @include vpSize(x7){
  max-width: 200px;
 }
}
.color-green{
  color: $darkgreen;
}
.landing-caption{
  top: 50% !important;
  transform: translateY(-50%);
  text-align: center;
}
.block-landing-welcome{
  figure{
    position: relative;
    margin-bottom: 0;
    width: 100%;
    padding-bottom: 120%;
    @include vpSize(x9){
       padding-bottom: 140%;
    }
      @include vpSize(w1){
        padding-bottom: 120%;
      }
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit:cover;
      z-index: 5;
    }
  }
.items{
  position: relative;
  padding: 0 3rem;
  margin-bottom: 1.5rem;
    @include vpSize(xsm){
        height: 1.1;
         padding: 0 4rem;
      }
      @include vpSize(x7){
          padding: 0 4.5rem;
      }
   @include vpSize(x9){
     margin-bottom: -3rem;
      padding: 0 5rem;
   }
}
.owl-dots{
  display: none;
}
.text-block{
  @include vpSize(x9){
      padding-left: 3rem;
  }
  .title{
    font-weight: 700;
    color: $theme;
    font-size: 2.3rem;
  }
   .pre-title{
      font-size: 1.3rem;
      height: 1.3rem;
      color: $black;
      margin:0;
    }
}
.js-owl-welcome-nav{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform:translateY(-50%);
  button{
    border:none;
    height: 2rem;
    width: 2rem;
    text-align: center;
    border-radius: 50%;
    background-color: $theme;
    margin: 0 .5rem;
    position: relative;
    @include vpSize(xsm){
        height: 2.2rem;
        width: 2.2rem;
      }
      @include vpSize(x7){
        height: 2.6rem;
        width: 2.6rem;
      }
      @include vpSize(x9){
         height: 3rem;
         width: 3rem;
      }
    // box-shadow: 0px 0px 10px 3px #00000059;
    svg{
      position: absolute;
      height: 1rem;
      width: auto;
      top: 50%;
      left: 50%;
      transform:translate(-50%, -50%);
      fill:$white;
      @include vpSize(xsm){
        height: 1.1;
      }
      @include vpSize(x7){
         height: 1.3rem;
      }
      @include vpSize(x9){
         height: 1.5rem;
      }
    }
    &.owl-prev{
      svg{
        left: 46%;
      }
    }
     &.owl-next{
      svg{
        left: 54%;
      }
    }
  }
}
}
.block-ser-area-listing{
  background-color: $gray;
  position: relative;
  &:after{
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 50px;
    background-image: url(../images/land-bk-top.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    // border-bottom: 50px solid $gray;
    // border-right: 100vw solid transparent;
    z-index: -1;
     @include vpSize(x7){
      // border-bottom: 100px solid $gray;
      height: 100px;
     }
  }
    &:before{
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
     background-image: url(../images/land-bk-bottom.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    // border-top: 50px solid $gray;
    // border-right: 100vw solid transparent;
    height: 50px;
    z-index: -1;
     @include vpSize(x7){
     //  border-top: 100px solid $gray;
     height: 100px;
     }
  }
}
.landing-banner-bottom-box{
  background-color: $theme;
  padding: 2rem;
  h4{
    color: $white;
    font-weight: 600;
    text-align: center;
    margin: 0;
    font-size: 1rem;
  }
}
.landing-banner-bottom-box-holder{
  margin-top: 1rem;
  position: relative;
  @include vpSize(x9){
    margin-top: 1.5rem;
  }
@include vpSize(x9){
  margin-top: 0;
  transform:translatey(3rem);
}
  &:after{
    position: absolute;
    top: 0;
    height: 100%;
    left: 10px;
    right: 10px;
    z-index: -1;
    display: block;
    content: "";
    background-color: $theme;
  }
}
.landing-banner{
  @include vpSize(x9){
 height: calc( 100vh - 3rem ) !important;
  }
}
.landing-listing{
  li{
    display: inline-block;
    vertical-align: middle;
    width: 49%;
        @include vpSize(x6){
         width: 32%
       }
         @include vpSize(x9){
          width: 24%;
         }
             @include vpSize(w2){
               width: 19%;
             }
  }
}
.landing-inner-listing{
  li{
    display: inline-block;
    vertical-align: top;
    width: 100%;
        @include vpSize(xsm){
         width: 49%
       }
         @include vpSize(x9){
          width: 33%;
         }
             @include vpSize(w2){
               width: 24%;
             }
  }
}
.block-ser-area-listing-inner{
  background-color: $gray;
}
#menu-top-menu-1 .menu-item-26 .sub-menu{
  position: fixed;
  top: 6.5rem;
  left: 10vw;
  width: 80vw;
  padding: 2rem !important;
  align-items:start;
  flex-wrap: wrap;
  background-color: $theme;
  z-index: 1;
  padding-top: 0!important;
  &:after{
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 1;
    background-color: transparent;
  }
  li{
    width: 33%;
    margin: .3rem 0;
    padding: .8rem !important;
    padding-bottom: 5% !important;
    position: relative;
    &.current-menu-item{
      a{
        font-weight: 500;
      }
    }
    a{
      position: absolute;
      width: 84%;
      top: 50%;
      right: 0;
      transform:translatey(-50%);
      font-weight: 300;
      color: $white;
      font-size: 1.1rem;
    }
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20%;
      background-image: url(../images/weapon.png);
      background-size: 40%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &:before{
    left: 44% !important;
  }
   &:after{
      height: 50px !important;
      top: -40px !important;
    }
}
#menu-top-menu-1 .menu-item-26:hover{
  .sub-menu{
    display: flex !important;
  }
}
// .fixed-header{
//   #menu-top-menu-1 .menu-item-26 .sub-menu{
//     top: 4.1rem;
//     &:after{
//       height: 40px;
//     }
//   }
// }
.stars{
  margin-bottom: 1.5rem;
  svg{
    fill:$white;
    display: inline-block;
    vertical-align: middle;
    height: 2rem;
    width: 2rem;
  }
}
.color-theme{
  color: $theme;
}
.owl-logos{
  a{
      display: block;
      width: 120px;
      height: 80px;
      position: relative;
      margin: 0 auto;
    img{
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0;
     left: 0;
     object-fit:contain;

    }
  }
}
.page-service-area{
  h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
  }
}
.contact-blocks{
  background-color: $gray;
}
#return-to-top {
  z-index: 999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: $theme;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: $black;
}
#return-to-top:hover i {
    color: #fff;
}
.block-footer{
  .title{
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
}
.block-footer{
  .menu-title{
    margin-bottom: 1rem;
  }
}

.block-video{
  display: block;
  position: relative;
  z-index: 0;
  background-color: $theme;
  padding: 1px;

  .video-block{
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;

    &::before{
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background-color: transparent;
    }
  }

  .icon-block{
    display: block;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);

    svg{
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  iframe,
  object,
  embed,
  img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
  }
}
.block-page-videos{
  .btn{
    margin: 0 5px;
  }
  .items{
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
  }
  .item{
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding: 0 10px 10px;
    @include vpSize(xsm){
      width: 50%;
    }
    @include vpSize(xmd){
      width: 33.333333%;
    }
  }
}
.widget_search{
  .widget-content{
    position: relative;
    z-index: 0;

    &::after{
      font-family: 'FontAwesome';
      content: "\f002";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      color: $theme;
    }
  }
  .widget-title{
    display: none;
  }
  label{
    display: block;
    margin: 0;
  }
  input[type="text"]{
    width: 100%;
    height: 3rem;
    border: solid 1px $theme;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 3rem;
  }
  input[type="submit"]{
    width: 3rem;
    height: 100%;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    text-indent: -9999999px;
  }
}
.logged-in{
  #menu-top-menu-1 .menu-item-26 .sub-menu{
    top: 8.5rem;
  }
}
.menu-item-548{
  .sub-menu{
      &:after{
      display: block!important;
      background-color: transparent;
      content: "";
      height: 30px;
top: -20px;
position: absolute;
/* top: 0; */
left: 0;
width: 100%;
     }
  }
}
.g--modal{
  .grecaptcha-badge{
    position: relative!important;
    left: 0!important;
    bottom: 0rem!important;
     @include vpSize(xsm){
     position: absolute!important;
    left: 0!important;
    bottom: -1rem!important;
    }
  }
  .wpforms-submit-container{
    @include vpSize(xsm){
    text-align: right;
     }
  }
}
#myModal{
  background-color: #002a6e5c;
  z-index: 1000;
  overflow: scroll;
}
.modal-info{
  position: absolute;
  top: 5rem;
  left: 50%;
  transform:translatex(-50%);
  padding: 3rem 2rem 2rem;
  border:1px solid $theme;
  color: $black;
  background-color: $white;
  z-index: 1001;
  max-width: 600px;
  width: 95%;
  margin-bottom: 1rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
     @include vpSize(xsm){
    padding: 4rem 3rem 3rem;
     }
     @include vpSize(xmd){
    padding: 5rem 4rem 4rem;
     transform:translate(-50%, -50%);
     top: 50%;
     margin-bottom: 0;
     }
  h2{
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $theme;
    position: relative;
    padding-bottom: 1rem;
    &:after{
      display: block;
      content: "";
      width: 200px;
      height: 1px;
      background-color: $theme;
      left: 50%;
      bottom: 0;
      transform:translateX(-50%);
      position: absolute;
    }
  }
    ul{
  list-style: none;
  padding: 0;
  li{
    position: relative;
    padding-left: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    color: $black;
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: .5rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border:1px solid $green;
    }
      &:before{
      display: block;
      content: "";
      position: absolute;
      top: .75rem;
      left: .25rem;
      height: .5rem;
      width: .5rem;
      background-color: $green;
      border-radius: 50%;
      z-index: 5;
    }
  }
}
  .close-btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
    svg{
      height: 2rem;
      width: 2rem;
      fill:$green;
    }
    &:hover{
      cursor: pointer;
    }
  }
}
