body {
  font-family: 'Roboto', sans-serif;
}

.block-tiny-dots .tns-nav button, .btn-menu {
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn, body div.wpforms-container-full .wpforms-form button[type=submit] {
  display: inline-block;
  background-color: transparent;
  color: #000000;
  border: solid 1px #7e7e7e;
  border-radius: 0;
  outline: none;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  padding: .5rem 2rem;
  font-size: .9rem;
  font-weight: 500;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 0;
  transition: .4s;
  text-shadow: none;
}

.btn:hover, body div.wpforms-container-full .wpforms-form button:hover[type=submit] {
  color: #002a6e;
  background-color: transparent;
  border-width: 1px;
  border-color: #002a6e;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn:hover, body div.wpforms-container-full .wpforms-form button:hover[type=submit], .btn:active, body div.wpforms-container-full .wpforms-form button:active[type=submit], .btn:focus, body div.wpforms-container-full .wpforms-form button:focus[type=submit] {
  outline: none;
  box-shadow: none;
}

.btn-radius {
  border-radius: 4rem;
}

.btn-skewed {
  transform: skew(-10deg);
}

.btn-arrow {
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  color: #ffffff;
  font-size: .9rem;
  font-weight: 600;
  transition: .4s;
  height: 21px;
  line-height: 21px;
}

.btn-arrow span {
  display: inline-block;
  vertical-align: middle;
}

.btn-arrow svg {
  height: 1.4rem;
  width: auto;
  display: inline-block;
  margin-left: .7rem;
  fill: #ffffff;
  vertical-align: middle;
  transition: .4s;
}

.btn-arrow:hover {
  color: #000000;
}

.btn-arrow:hover svg {
  margin-left: 1.7rem;
  fill: #000000;
}

.btn-border {
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  padding: .5rem 2rem;
  font-size: .9rem;
  position: relative;
  overflow: hidden;
  transition: .4s;
  display: inline-block;
}

.btn-border.white-btn {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btn-border.white-btn:hover {
  color: #002a6e;
  border-color: #ffffff !important;
}

.btn-border.white-btn:hover:after {
  width: 100%;
  left: 0;
  background-color: #ffffff;
}

.btn-border.black-btn {
  color: #000000;
  border: 1px solid #000000;
}

.btn-border.black-btn:hover {
  color: #ffffff;
  border-color: #002a6e !important;
}

.btn-border.black-btn:hover:after {
  width: 100%;
  left: 0;
  background-color: #002a6e;
}

.btn-border.span-btn span {
  position: relative;
  z-index: 3;
}

.btn-border.span-btn:after {
  z-index: 1;
}

.btn-border:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 0%;
  z-index: -1;
  transition: .4s;
}

.block-header .btn-border {
  margin-right: 1.5rem;
  font-weight: 300;
  font-size: 1.1rem;
  padding: .5rem 1.5rem;
}

.btn-contact {
  color: #ffffff;
  font-weight: 400;
}

.btn-contact span {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  line-height: .9rem;
}

@media (min-width: 768px) {
  .btn-contact span {
    font-size: 1.2rem;
  }
}

.btn-contact span small {
  font-size: 1.2rem;
  color: #8aeb2b;
  font-weight: 300;
  display: block;
  padding-top: .3rem;
  letter-spacing: 1px;
}

.btn-contact svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: #8aeb2b;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .btn-contact svg {
    height: 1.8rem;
    width: 1.8rem;
  }
}

@media (min-width: 768px) {
  .btn-contact svg {
    margin-right: .5rem;
  }
}

.btn-contact:hover {
  color: #ffffff;
}

.btn-round {
  display: block;
  height: 4rem;
  width: 4rem;
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #002a6e;
  margin: 0rem;
  border-radius: 50%;
  border-style: none;
  box-shadow: 0px 0px 10px 3px #00000059;
  transition: .4a;
}

.btn-round svg {
  height: 1.8rem;
  width: auto;
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.wpforms-submit {
  position: relative !important;
  overflow: hidden !important;
}

.wpforms-submit:hover {
  color: #ffffff !important;
}

.field-block, body div.wpforms-container-full .wpforms-form {
  position: relative;
  text-align: left;
  margin-bottom: 1rem;
}

.field-block label, body div.wpforms-container-full .wpforms-form label {
  font-weight: 400;
  color: #5f5f5f;
}

.field-block input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), body div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), .field-block textarea, body div.wpforms-container-full .wpforms-form textarea, .field-block select, body div.wpforms-container-full .wpforms-form select {
  width: 100%;
  border: solid 1px #7e7e7e;
  border-radius: 0;
  padding: .5rem 1rem;
  height: 3rem;
  font-size: .9rem;
  font-weight: 400;
  position: relative;
  z-index: 1;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: .4s;
  color: #5f5f5f !important;
}

.field-block input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, body div.wpforms-container-full .wpforms-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):focus, .field-block textarea:focus, body div.wpforms-container-full .wpforms-form textarea:focus, .field-block select:focus, body div.wpforms-container-full .wpforms-form select:focus {
  border-color: #002a6e;
}

.field-block input[type="file"], body div.wpforms-container-full .wpforms-form input[type="file"] {
  padding-top: .7rem !important;
  height: auto !important;
}

.field-block select, body div.wpforms-container-full .wpforms-form select {
  background-image: url("../images/icon-sort.svg");
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: .7rem;
  padding-right: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.field-block textarea, body div.wpforms-container-full .wpforms-form textarea,
.field-block textarea.wpforms-field-large,
body div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
  height: 6rem;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .field-block textarea, body div.wpforms-container-full .wpforms-form textarea,
  .field-block textarea.wpforms-field-large,
  body div.wpforms-container-full .wpforms-form textarea.wpforms-field-large {
    height: 8rem;
  }
}

.field-check li, body div.wpforms-container-full .wpforms-form li {
  position: relative;
  margin-bottom: 1rem !important;
}

.field-check li input[type=radio], body div.wpforms-container-full .wpforms-form li input[type=radio],
.field-check li input[type=checkbox],
body div.wpforms-container-full .wpforms-form li input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  appearance: none;
  border: none;
  height: 20px;
  width: 20px;
}

.field-check li label, body div.wpforms-container-full .wpforms-form li label {
  padding-left: 25px;
}

.field-check li label::before, body div.wpforms-container-full .wpforms-form li label::before, .field-check li label::after, body div.wpforms-container-full .wpforms-form li label::after {
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transition: .4s;
}

.field-check li label::before, body div.wpforms-container-full .wpforms-form li label::before {
  border: solid 1px #002a6e;
}

.field-check li label::after, body div.wpforms-container-full .wpforms-form li label::after {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: 14px;
  height: 14px;
  background-color: #002a6e;
  transform: scale(0);
  opacity: 0;
}

.field-check li label div, body div.wpforms-container-full .wpforms-form li label div {
  display: inline-block;
  padding-left: .5rem;
  margin-top: -4px;
}

.field-check li label div b, body div.wpforms-container-full .wpforms-form li label div b {
  font-size: 1rem;
}

.field-check li label div span, body div.wpforms-container-full .wpforms-form li label div span {
  font-size: .9rem;
}

.field-check li input[type=radio]:checked + label::after, body div.wpforms-container-full .wpforms-form li input[type=radio]:checked + label::after,
.field-check li input[type=checkbox]:checked + label::after,
body div.wpforms-container-full .wpforms-form li input[type=checkbox]:checked + label::after {
  transform: scale(1);
  opacity: 1;
}

.g--overlay {
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  z-index: 1004;
}

.g--modal,
.g--overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.g--modal {
  z-index: 2000;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  visibility: hidden;
  -webkit-transition: all .4s;
  transition: all .4s;
  text-align: center;
  display: none;
}

.g--modal.active {
  display: block;
  opacity: 1;
  visibility: visible;
}

.g--modal.active .content {
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}

.g--modal .content {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 600px;
  max-width: 90%;
  margin: 5% auto;
  background-color: #fff;
  -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-400px) scale(0.7);
  transform: translateY(-400px) scale(0.7);
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transition: all .4s;
  transition: all .4s;
  text-align: left;
}

.g--modal .content ._close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.g--modal .content ._close::after,
.g--modal .content ._close::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  content: '';
  background-color: #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all .4s;
  transition: all .4s;
}

.g--modal .content ._close:before {
  width: 90%;
  height: 2px;
}

.g--modal .content ._close:after {
  width: 2px;
  height: 90%;
}

.g--modal .content .close:hover:after,
.g--modal .content .close:hover:before {
  opacity: .8;
}

.g--modal .content ._close:hover:before {
  width: 80%;
}

.g--modal .content ._close:hover:after {
  height: 80%;
}

.g--modal .data {
  padding: 60px 20px 25px;
}

@media (min-width: 768px) {
  .g--modal .data {
    padding: 50px 40px;
  }
}

.g--modal header {
  margin-bottom: 30px;
}

.g--modal h2 {
  text-align: center;
  margin-bottom: 10px;
}

.g--modal h2,
.g--modal p {
  font-weight: 300 font-size2rem;
}

.g--modal .wpforms-submit-container {
  text-align: center;
}

#wpforms-16-field_9 {
  border: 1px solid #7e7e7e !important;
  color: #5f5f5f !important;
}

#wpforms-551-field_9 {
  border: 1px solid #7e7e7e !important;
  color: #5f5f5f !important;
}

.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 123;
  width: 100%;
  height: 100%;
  background-color: #0d2c5b;
  opacity: .3;
  transition: .4s;
}

.block-modal {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 125;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all .4s;
  text-align: center;
}

.block-modal.modal-open {
  opacity: 1;
}

.block-modal.modal-open .content {
  transform: scale(1);
  opacity: 1;
}

.block-modal .content {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 650px;
  max-width: 90%;
  margin: 5% auto;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
  opacity: 0;
  transition-delay: .2s;
  transition: all .6s;
  text-align: left;
}

.block-modal .content .cross {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 1s;
}

.block-modal .content .cross::before, .block-modal .content .cross::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  content: '';
  background-color: #002a6e;
  transform: rotate(45deg);
  transition: all .4s;
}

.block-modal .content .cross::before {
  width: 90%;
  height: 2px;
}

.block-modal .content .cross::after {
  width: 2px;
  height: 90%;
}

.block-modal .content .cross:hover::before, .block-modal .content .cross:hover::after {
  opacity: 0.8;
}

.block-modal .content .cross:hover::before {
  width: 80%;
}

.block-modal .content .cross:hover::after {
  height: 80%;
}

.block-modal .data-block {
  padding: 2rem 1rem;
}

.block-modal .wpforms-submit-container {
  text-align: center;
}

.block-pagination {
  margin-top: 2rem;
}

.block-pagination a,
.block-pagination span {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border: solid 1px #002a6e;
  margin: 0 2px;
  padding: 0 .5rem;
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #5f5f5f;
}

.block-pagination a {
  cursor: pointer;
}

.block-pagination .current {
  border-color: #002a6e;
  background-color: #002a6e;
  color: #ffffff;
}

.title-block {
  letter-spacing: -1px;
  font-weight: 400;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

body .wpforms-container {
  text-align: left;
}

body div.wpforms-container-full {
  margin-bottom: 0;
}

body div.wpforms-container-full .wpforms-form {
  margin-bottom: 0;
}

body div.wpforms-container-full .wpforms-form .wpforms-one-half {
  width: 100%;
  margin-left: 0;
}

@media (min-width: 575px) {
  body div.wpforms-container-full .wpforms-form .wpforms-one-half {
    width: 48%;
    margin-left: 4%;
  }
}

body div.wpforms-container-full .wpforms-form .wpforms-field-label {
  font-weight: 400;
}

body div.wpforms-container-full .wpforms-form button[type=submit] {
  text-transform: uppercase;
}

body div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
  padding: 0;
}

body div.wpforms-container-full .wpforms-form .grecaptcha-badge {
  z-index: 97;
}

body span.wpcf7-not-valid-tip {
  font-size: .9rem;
  margin-top: 5px;
}

body div.wpcf7-response-output {
  text-align: center;
}

.mejs__overlay-button {
  position: relative;
  background-color: rgba(219, 53, 42, 0.4) !important;
  border-radius: 50%;
  background-image: none;
  outline: none;
}

.mejs__overlay-button::before {
  width: 60%;
  height: 60%;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(219, 53, 42, 0.8);
  transition: .4s;
}

.mejs__overlay-button::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #ffffff;
}

.mejs__overlay-button:hover::before {
  animation: rounscale 2s ease-in-out infinite;
}

@keyframes rounscale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.pf-control-bar-button g,
.pf-control-bar-pager g {
  fill: #5f5f5f;
}

#pf-pagerin {
  color: #5f5f5f;
}

.block-owl-nav,
.block-tiny-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translateY(-50%);
  margin: auto;
  height: 1px;
}

.block-owl-nav button,
.block-tiny-nav button {
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  color: #5f5f5f;
  border: none;
  outline: none;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  transform: translateX(50%);
  transition: .4s;
}

.block-owl-nav button:first-child,
.block-tiny-nav button:first-child {
  transform: translateX(-50%);
}

.block-owl-nav button svg,
.block-tiny-nav button svg {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.block-owl-nav button svg polygon,
.block-tiny-nav button svg polygon {
  fill: #a7a7a7;
  stroke-width: 1px;
  stroke: #a7a7a7;
  transition: .4s;
}

.block-owl-nav button:hover,
.block-tiny-nav button:hover {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}

.block-owl-nav button:hover svg polygon,
.block-tiny-nav button:hover svg polygon {
  fill: #5f5f5f;
  stroke: #5f5f5f;
}

.block-tiny-dots .tns-nav {
  text-align: center;
  margin-top: 2rem;
}

.block-tiny-dots .tns-nav button {
  background-color: #dadce0;
  border-radius: 3px;
  width: 3rem;
  height: 4px;
  margin: 0 5px;
  padding: 0;
  transition: .4s;
}

.block-tiny-dots .tns-nav button.tns-nav-active {
  background-color: #ff0000;
}

.sl-overlay {
  background-color: #1e1e1e !important;
  opacity: .9 !important;
}

.sl-wrapper .sl-navigation button {
  color: #5f5f5f !important;
}

.sl-wrapper .sl-close,
.sl-wrapper .sl-counter {
  color: #5f5f5f !important;
}

.sl-wrapper .sl-image .sl-caption {
  display: none !important;
}

.ngg-navigation a, .ngg-navigation span {
  font-size: 1.1rem;
  display: inline-block !important;
  vertical-align: middle !important;
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
  line-height: calc(2rem - 2px) !important;
  padding: 0 !important;
  text-align: center !important;
  background-color: transparent !important;
  border: solid 1px #002a6e !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.ngg-navigation span {
  background-color: #002a6e !important;
}

body {
  margin: 0;
  height: auto !important;
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

body.modal-open {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body.admin-bar .block-header,
body.admin-bar .block-mobile-nav {
  top: 32px;
}

a {
  text-decoration: none;
  outline-width: 0;
}

a:hover {
  text-decoration: none;
  outline-width: 0;
}

a:active {
  text-decoration: none;
  outline-width: 0;
}

a:visited {
  text-decoration: none;
  outline-width: 0;
}

a:focus {
  text-decoration: none;
  outline-width: 0;
}

button,
input,
select,
textarea {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:hover,
input:hover,
select:hover,
textarea:hover {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:active,
input:active,
select:active,
textarea:active {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:visited,
input:visited,
select:visited,
textarea:visited {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

.h1,
h1 {
  font-size: 1.9rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

@media (min-width: 575px) {
  .h1,
  h1 {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .h1,
  h1 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .h1,
  h1 {
    font-size: 2.3rem;
  }
}

.h2,
h2 {
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}

@media (min-width: 575px) {
  .h2,
  h2 {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .h2,
  h2 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .h2,
  h2 {
    font-size: 3rem;
  }
}

.h3,
h3 {
  font-size: 1.8rem;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}

@media (min-width: 575px) {
  .h3,
  h3 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .h3,
  h3 {
    font-size: 2.1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .h3,
  h3 {
    font-size: 2.3rem;
  }
}

.h4,
h4 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .h4,
  h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .h4,
  h4 {
    font-size: 1.3rem;
  }
}

.h5,
h5 {
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .h5,
  h5 {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) {
  .h5,
  h5 {
    font-size: 1.1rem;
  }
}

.h6,
h6 {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .h6,
  h6 {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) {
  .h6,
  h6 {
    font-size: 1.1rem;
  }
}

a {
  color: #002a6e;
}

a:hover {
  color: inherit;
}

p {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  p {
    margin-bottom: 1.5rem;
  }
}

small {
  font-size: 90%;
  font-weight: inherit;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

label {
  font-weight: 400;
}

button {
  color: inherit;
  font-weight: inherit;
}

button:focus {
  outline: none;
}

nav {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.menu-btn-block {
  margin-left: 1rem;
}

.menu-btn-block a {
  font-size: .8rem;
  color: #000000;
}

.menu-btn-block a:hover span:nth-child(1n) {
  width: 100%;
}

.menu-btn-block a:hover span:nth-child(2n) {
  width: 70%;
}

.menu-btn-block .icon {
  width: 30px;
  margin-right: 1rem;
}

.menu-btn-block .icon span {
  height: 2px;
  width: 70%;
  margin: 4px auto;
  background-color: #ffffff;
  display: block;
  transition: .4s;
}

.menu-btn-block .icon span:nth-child(2n) {
  width: 100%;
}

.block-mobile-nav {
  background-color: #ffffff;
  color: #000000;
  font-weight: 400;
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -260px;
  z-index: 1050;
  transition: 0.4s;
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.block-mobile-nav.active {
  left: 0;
}

.block-mobile-nav .data-block {
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 1rem 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.block-mobile-nav a {
  transition: .4s;
}

.block-mobile-nav nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.block-mobile-nav nav ul li {
  position: relative;
  margin: 0;
  padding: 0.5rem 0;
}

.block-mobile-nav nav ul li a {
  display: block;
  color: #000000;
  font-size: .9rem;
  text-transform: uppercase;
}

.block-mobile-nav nav ul li a:hover {
  color: #000000;
}

.block-mobile-nav nav ul li a.active {
  color: #000000;
}

.mobile-nav-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background-color: #002a6e;
  opacity: .5;
}

.mobile-nav-mask.active {
  transform: translateX(0);
}

.quote-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background-color: #002a6e;
  opacity: .5;
}

.quote-mask.active {
  transform: translateX(0);
}

.btn-menu {
  position: relative;
  width: 3rem;
  padding: 0;
  cursor: pointer;
  text-align: right;
  background: none;
  margin-left: 1rem;
}

.btn-menu.active span {
  transform: translateY(-90px);
  opacity: 0;
}

.btn-menu.active span:nth-child(2) {
  width: 100%;
  transform: translateY(0);
  opacity: 1;
}

.btn-menu.active span:nth-child(2)::before {
  transform: rotate(45deg);
}

.btn-menu.active span:nth-child(2)::after {
  transform: rotate(-45deg);
}

.btn-menu.active span:nth-child(3) {
  transform: translateY(90px);
}

.btn-menu div {
  width: 1.4rem;
  margin: .5rem auto;
}

.btn-menu div::after {
  display: block;
  clear: both;
  content: '';
}

.btn-menu span {
  display: block;
  float: right;
  position: relative;
  width: 100%;
  height: 3px;
  margin: 2px 0;
  border-radius: 3px;
  background-color: #5ea7ad;
  transition: .4s;
}

.btn-menu span:nth-child(2) {
  background-color: transparent;
}

.btn-menu span:nth-child(2)::before, .btn-menu span:nth-child(2)::after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #5ea7ad;
  transition: .4s;
}

.close {
  position: relative;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 99;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #002a6e;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.block-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: .8rem;
  padding-bottom: .8rem;
  z-index: 999;
  transition: .4s;
  background-color: #002a6e;
}

@media (min-width: 992px) {
  .block-header.fixed-header {
    padding-top: .8rem;
    padding-bottom: .8rem;
  }
}

.block-logo {
  width: 110px;
  transition: .4s;
}

@media (min-width: 575px) {
  .block-logo {
    width: 140px;
  }
}

@media (min-width: 768px) {
  .block-logo {
    width: 200px;
  }
}

.block-logo a {
  display: block;
}

.block-logo a img {
  width: 100%;
  height: auto;
  margin-bottom: -8px;
}

.block-top-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.block-top-nav ul li {
  margin: 0;
  padding-left: 0;
  margin-right: .9rem;
}

@media (min-width: 1100px) {
  .block-top-nav ul li {
    margin-right: 1rem;
    font-size: 1.1rem;
  }
}

.block-top-nav ul li a {
  color: #ffffff;
  font-weight: 300;
}

.block-top-nav ul li.current-menu-item a {
  font-weight: 500;
}

.block-banner {
  position: relative;
}

.block-banner .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 10;
  width: 100%;
  text-align: right;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
}

@media (min-width: 992px) {
  .block-banner .owl-dots {
    bottom: 240px;
  }
}

@media (min-width: 1100px) {
  .block-banner .owl-dots {
    bottom: 220px;
  }
}

.block-banner .owl-dots .owl-dot span {
  height: 5px;
  width: 1.4rem;
  border: 1px solid #ffffff;
  display: block;
  margin: 3px;
}

.block-banner .owl-dots .owl-dot.active span {
  background-color: #ffffff;
}

.banner-block {
  position: relative;
}

.banner-block .videolink {
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 20;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

@media (min-width: 575px) {
  .banner-block .videolink {
    top: 70%;
    left: 50%;
    height: 4rem;
    width: 4rem;
  }
}

@media (min-width: 768px) {
  .banner-block .videolink {
    top: 60%;
    height: 5rem;
    width: 5rem;
  }
}

@media (min-width: 992px) {
  .banner-block .videolink {
    top: 50%;
  }
}

.banner-block .videolink svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-39%, -50%);
  height: 2rem;
  width: 2rem;
  fill: #ffffff;
}

@media (min-width: 575px) {
  .banner-block .videolink svg {
    height: 2.5rem;
    width: 2.5rem;
  }
}

@media (min-width: 768px) {
  .banner-block .videolink svg {
    height: 3rem;
    width: 3rem;
  }
}

.banner-block .video-block {
  position: relative;
  z-index: 0;
  height: 100%;
  height: 500px;
}

@media (min-width: 575px) {
  .banner-block .video-block {
    height: 700px;
  }
}

@media (min-width: 768px) {
  .banner-block .video-block {
    height: 100vh;
  }
}

.banner-block .video-block:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: .6;
  z-index: 2;
}

.banner-block .video-block video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  -o-object-fit: fill;
  object-fit: fill;
  transform: translateX(-50%) translateY(-50%);
}

.banner-block figure {
  position: relative;
  margin: 0;
  z-index: 1;
  height: 500px;
}

@media (min-width: 575px) {
  .banner-block figure {
    height: 700px;
  }
}

@media (min-width: 768px) {
  .banner-block figure {
    height: 100vh;
  }
}

.banner-block figure:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: .6;
  z-index: 2;
}

.banner-block figure img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
}

.banner-block .banner-caption {
  position: absolute;
  top: 22%;
  left: 0;
  color: #ffffff;
  z-index: 5;
  width: 100%;
}

@media (min-width: 575px) {
  .banner-block .banner-caption {
    top: 30%;
  }
}

@media (min-width: 992px) {
  .banner-block .banner-caption {
    top: 20%;
  }
}

@media (min-width: 1100px) {
  .banner-block .banner-caption {
    top: 26%;
  }
}

.banner-block .banner-caption h2, .banner-block .banner-caption h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  color: #ffffff;
}

@media (min-width: 575px) {
  .banner-block .banner-caption h2, .banner-block .banner-caption h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .banner-block .banner-caption h2, .banner-block .banner-caption h1 {
    font-size: 3rem;
  }
}

.banner-block .banner-caption p {
  margin-bottom: 2rem;
}

.banner-bottom-boxes {
  position: relative;
  width: 100%;
}

.banner-bottom-boxes .container-fluid {
  max-width: 1200px;
  padding: 0;
}

@media (min-width: 1281px) {
  .banner-bottom-boxes .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .banner-bottom-boxes {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
  }
}

.banner-bottom-box {
  position: relative;
}

@media (min-width: 575px) {
  .banner-bottom-box {
    padding-bottom: 70%;
  }
}

@media (min-width: 600px) {
  .banner-bottom-box {
    padding-bottom: 55%;
  }
}

@media (min-width: 768px) {
  .banner-bottom-box {
    padding-bottom: 120%;
  }
}

@media (min-width: 801px) {
  .banner-bottom-box {
    padding-bottom: 100%;
  }
}

@media (min-width: 992px) {
  .banner-bottom-box {
    padding-bottom: 90%;
  }
}

@media (min-width: 1100px) {
  .banner-bottom-box {
    padding-bottom: 70%;
  }
}

.banner-bottom-box figure {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.banner-bottom-box figure:after {
  display: block;
  content: "";
  position: absolute;
  top: -3%;
  left: 0;
  height: 106%;
  width: 100%;
  background-color: #000000;
  opacity: .7;
  z-index: 2;
}

.banner-bottom-box figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.banner-bottom-box .content {
  z-index: 3;
  padding: 2rem 1.8rem;
  position: relative;
  color: #ffffff;
}

@media (min-width: 575px) {
  .banner-bottom-box .content {
    position: absolute;
    left: 0;
    top: 0;
    font-size: .9rem;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .banner-bottom-box .content {
    padding: 1rem .8rem;
  }
}

@media (min-width: 992px) {
  .banner-bottom-box .content {
    padding: 2rem 1.8rem;
  }
}

.banner-bottom-box .content .title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
}

@media (min-width: 1100px) {
  .banner-bottom-box .content .title {
    font-size: 1.16rem;
  }
}

.banner-bottom-box .content p:last-child {
  margin-bottom: 0;
}

.banner-bottom-box.big-content {
  background-color: #002a6e;
  text-align: center;
}

.banner-bottom-box.big-content .content {
  font-size: 1.5rem;
}

@media (min-width: 575px) {
  .banner-bottom-box.big-content .content {
    top: 50%;
    transform: translateY(-50%);
  }
}

.banner-bottom-box.big-content .content .title {
  font-size: 6.8rem;
  line-height: 6rem;
  font-weight: 800;
  margin: 0;
}

@media (min-width: 768px) {
  .banner-bottom-box.big-content .content .title {
    font-size: 5.5rem;
  }
}

@media (min-width: 992px) {
  .banner-bottom-box.big-content .content .title {
    font-size: 6.8rem;
  }
}

@media (min-width: 1100px) {
  .banner-bottom-box.big-content .content .title {
    font-size: 7.8rem;
  }
}

.banner-bottom-box.big-content .content p {
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 100;
}

.block-hm-about figure {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .block-hm-about figure {
    margin-top: 5rem;
  }
}

.block-hm-about figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left 15% top 0%;
}

.block-hm-about .content {
  padding-top: 2rem;
}

@media (min-width: 992px) {
  .block-hm-about .content {
    padding: 0;
  }
}

.block-hm-about .content .pre-title {
  font-size: 1.3rem;
  height: 1.3rem;
  color: #000000;
  margin: 0;
}

.block-hm-about .content .title {
  font-size: 2.5rem;
  color: #002a6e;
  font-weight: 700;
  line-height: 1;
}

@media (min-width: 575px) {
  .block-hm-about .content .title {
    font-size: 3.2rem;
  }
}

@media (min-width: 768px) {
  .block-hm-about .content .title {
    font-size: 3.5rem;
  }
}

@media (min-width: 992px) {
  .block-hm-about .content .title {
    font-size: 3.7rem;
  }
}

@media (min-width: 1100px) {
  .block-hm-about .content .title {
    font-size: 4rem;
  }
}

.block-hm-about .content .btn-border {
  margin-top: 1rem;
  display: inline-block;
}

.home-service {
  position: relative;
  padding-bottom: 140%;
  opacity: .5;
}

@media (min-width: 575px) {
  .home-service {
    opacity: 1;
  }
}

.home-service figure {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  transition: .4s;
  box-shadow: 0px 10px 10px 2px #0000007a;
}

@media (min-width: 575px) {
  .home-service figure {
    box-shadow: 0px 15px 15px 3px #0000007a;
  }
}

.home-service figure:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  background-color: #000000;
  opacity: .5;
}

.home-service figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.home-service .content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem 1.5rem;
  width: 100%;
  z-index: 5;
}

.home-service .content .title {
  font-size: .9rem;
  height: 7rem;
  position: relative;
  color: #002a6e;
  font-weight: 600;
}

@media (min-width: 400px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 6.5rem;
  }
}

@media (min-width: 575px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 6.5rem;
  }
}

@media (min-width: 768px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 6.5rem;
  }
}

@media (min-width: 992px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 6rem;
  }
}

@media (min-width: 1025px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 6.5rem;
  }
}

@media (min-width: 1281px) {
  .home-service .content .title {
    font-size: 1rem;
    height: 5.5rem;
  }
}

.home-service .content .title:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #002a6e;
}

.home-service .content a {
  display: flex;
}

.home-service .content a span {
  font-size: .8rem;
  color: #002a6e;
  transition: .4s;
}

@media (min-width: 400px) {
  .home-service .content a span {
    font-size: .9rem;
  }
}

.home-service .content a img {
  width: 20px;
  transition: .4s;
}

@media (min-width: 400px) {
  .home-service .content a img {
    width: 25px;
  }
}

@media (min-width: 575px) {
  .home-service .content a img {
    width: 30px;
  }
}

.home-service:hover figure {
  opacity: 1;
}

.home-service:hover .content .title {
  color: #ffffff;
}

.home-service:hover .content .title:after {
  background-color: #ffffff;
}

.home-service:hover .content a span {
  color: #ffffff;
}

.home-service:hover .content a img {
  filter: brightness(0) invert(1);
}

.block-home-services {
  margin-right: -8%;
  margin-left: -50%;
  position: relative;
}

@media (min-width: 575px) {
  .block-home-services {
    margin-right: -8%;
    margin-left: -30px;
  }
}

.block-home-services .owl-stage-outer {
  padding-left: 30px;
  padding-right: 30px;
}

.block-home-services:after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 45.5%;
  background-color: #ffffff;
  opacity: 0;
  z-index: 5;
}

@media (min-width: 575px) {
  .block-home-services:after {
    width: 30.5%;
    display: block;
  }
}

@media (min-width: 768px) {
  .block-home-services:after {
    width: 28.5%;
  }
}

@media (min-width: 1025px) {
  .block-home-services:after {
    width: 17.5%;
  }
}

.block-home-services:before {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 36%;
  background-color: #ffffff;
  opacity: .7;
  z-index: 5;
}

@media (min-width: 575px) {
  .block-home-services:before {
    width: 24.5%;
    display: block;
  }
}

@media (min-width: 768px) {
  .block-home-services:before {
    width: 26%;
  }
}

@media (min-width: 992px) {
  .block-home-services:before {
    width: 19%;
  }
}

@media (min-width: 1025px) {
  .block-home-services:before {
    width: 16%;
  }
}

.js-owl-services-nav .owl-prev {
  display: none;
}

.js-owl-services-nav .owl-next {
  height: 4rem;
  width: 4rem;
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #002a6e;
  margin: 0rem;
  border-radius: 50%;
  position: absolute;
  top: 63%;
  right: 9%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 6;
  border-style: none;
  box-shadow: 0px 0px 10px 3px #00000059;
}

.js-owl-services-nav .owl-next svg {
  height: 1.8rem;
  width: auto;
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.home-txt-btn {
  padding-right: 6rem;
  position: relative;
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .home-txt-btn {
    margin-top: 0;
  }
}

.home-txt-btn .title {
  font-size: 1.5rem;
  font-weight: 600;
}

.home-txt-btn .btn-round {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

figure.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
}

figure.background img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

figure.background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: .6;
  z-index: 0;
}

.block-testimonials-hm {
  position: relative;
  z-index: 0;
}

.block-testimonials-hm .video-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
  overflow: hidden;
}

.block-testimonials-hm .video-block:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: .6;
  z-index: 2;
}

.block-testimonials-hm .video-block video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  -o-object-fit: fill;
  object-fit: fill;
  transform: translateX(-50%) translateY(-50%);
}

.block-testimonials-hm .content {
  max-width: 700px;
  position: relative;
  z-index: 5;
  text-align: center;
  margin: 0 auto;
  line-height: 1.2;
  color: #ffffff;
}

.block-testimonials-hm .content .title {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.9rem;
  margin-bottom: 3rem;
}

.block-testimonials-hm .content figure {
  width: 4rem;
  position: relative;
  padding-bottom: 5rem;
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.block-testimonials-hm .content figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.block-testimonials-hm .content .name {
  font-size: .9rem;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: .9rem;
}

.block-testimonials-hm .content .place {
  font-size: .8rem;
}

.cr-logo-block {
  display: inline-block;
  height: auto;
  margin: 0 3.5rem;
}

.cr-logo-block img {
  width: 100%;
  height: auto;
  width: auto;
  max-width: 7rem;
  max-height: 6rem;
}

.block-home-quote {
  text-align: center;
  position: relative;
}

.block-home-quote .back-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 70%;
  min-height: 700px;
}

.block-home-quote .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.block-home-quote .content .title {
  font-size: 1.9rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  font-weight: 500;
}

.block-home-quote .content p {
  margin-bottom: 3rem;
}

.block-home-quote .content .btn-border {
  border: 2px solid #000000;
}

.footer-caption .title {
  text-transform: uppercase;
  font-size: 1.1rem;
  color: #002a6e;
  margin-bottom: 1.5rem;
}

@media (min-width: 575px) {
  .footer-caption .title {
    font-size: 1.25rem;
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 768px) {
  .footer-caption .title {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
}

.footer-menu {
  font-size: .9rem;
}

.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-menu ul li {
  width: 49%;
  display: inline-block;
  padding: 0 0 1rem;
}

.footer-menu ul li a {
  color: #080808;
  transition: .4s;
}

.footer-menu ul li a:hover {
  color: #002a6e;
}

.footer-nav {
  font-size: .9rem;
  color: #080808;
}

.footer-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-nav ul li {
  padding: 0 0 1rem;
}

.footer-nav ul li a {
  color: #080808;
  transition: .4s;
}

.footer-nav ul li a:hover {
  color: #002a6e;
}

.footer-bottom {
  border-top: 1px solid #ebebeb;
  padding-top: 1.5rem;
}

.footer-social ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-social ul li {
  padding: 0;
  padding-right: 1rem;
}

.footer-social ul li a {
  font-size: 1.5rem;
  color: #002a6e;
}

.copy-right {
  font-size: .9rem;
  color: #080808;
}

.copy-right a {
  display: inline-block;
}

.copy-right a img {
  height: 1em;
}

.column-block {
  margin-left: -3px;
  margin-right: -3px;
}

.column-block:after {
  display: block;
  content: "";
  clear: both;
}

.column-block .column {
  float: left;
}

.column-block .column:after {
  display: block;
  content: "";
  clear: both;
}

.column-block .column .photo {
  padding: 3px;
}

.column-block .column .photo figure {
  position: relative;
  width: 100%;
  margin: 0;
}

.column-block .column .photo figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.column-block .column-01 {
  width: 100%;
}

@media (min-width: 575px) {
  .column-block .column-01 {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .column-block .column-01 {
    width: 21%;
  }
}

.column-block .column-01 .photo-1 {
  width: 50%;
  float: right;
}

@media (min-width: 575px) {
  .column-block .column-01 .photo-1 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .column-block .column-01 .photo-1 {
    padding-top: 92%;
  }
}

.column-block .column-01 .photo-1 figure {
  padding-bottom: 92%;
}

@media (min-width: 992px) {
  .column-block .column-01 .photo-1 figure {
    padding-bottom: 140%;
  }
}

.column-block .column-01 .photo-2 {
  width: 50%;
  float: right;
}

@media (min-width: 575px) {
  .column-block .column-01 .photo-2 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .column-block .column-01 .photo-2 {
    width: 55%;
  }
}

.column-block .column-01 .photo-2 figure {
  padding-bottom: 92%;
}

@media (min-width: 575px) {
  .column-block .column-01 .photo-2 figure {
    padding-bottom: 59%;
  }
}

@media (min-width: 992px) {
  .column-block .column-01 .photo-2 figure {
    padding-bottom: 110%;
  }
}

.column-block .column-02 {
  width: 100%;
}

@media (min-width: 575px) {
  .column-block .column-02 {
    width: 60%;
  }
}

.column-block .column-02 .photo-2 {
  width: 45%;
  float: right;
}

.column-block .column-02 .photo-2 figure {
  padding-bottom: 61.6%;
}

.column-block .column-02 .photo-1 {
  width: 55%;
  float: right;
}

.column-block .column-02 .photo-1 figure {
  padding-bottom: 50%;
}

.column-block .column-02 .photo-3 {
  width: 100%;
  float: right;
}

.column-block .column-02 .photo-3 figure {
  padding-bottom: 73.1%;
}

.column-block .column-03 {
  width: 50%;
}

@media (min-width: 992px) {
  .column-block .column-03 {
    width: 19%;
  }
}

.column-block .column-03 .photo-2 {
  width: 100%;
  float: left;
}

.column-block .column-03 .photo-2 figure {
  padding-bottom: 70%;
}

@media (min-width: 992px) {
  .column-block .column-03 .photo-2 figure {
    padding-bottom: 169.5%;
  }
}

.column-block .column-03 .photo-1 {
  float: left;
  width: 100%;
}

@media (min-width: 992px) {
  .column-block .column-03 .photo-1 {
    padding-top: 94%;
    width: 55%;
  }
}

.column-block .column-03 .photo-1 figure {
  padding-bottom: 50%;
}

@media (min-width: 992px) {
  .column-block .column-03 .photo-1 figure {
    padding-bottom: 110%;
  }
}

.column-block .column-04 {
  width: 50%;
}

@media (min-width: 992px) {
  .column-block .column-04 {
    width: 100%;
  }
}

.column-block .column-04 .photo-1 {
  width: 100%;
  float: right;
}

@media (min-width: 992px) {
  .column-block .column-04 .photo-1 {
    width: 21%;
  }
}

.column-block .column-04 .photo-1 figure {
  padding-bottom: 60%;
}

.column-block .column-04 .photo-2 {
  width: 100%;
  float: right;
}

@media (min-width: 992px) {
  .column-block .column-04 .photo-2 {
    width: 10.2%;
  }
}

.column-block .column-04 .photo-2 figure {
  padding-bottom: 60%;
}

@media (min-width: 992px) {
  .column-block .column-04 .photo-2 figure {
    padding-bottom: 100%;
  }
}

.column-block .column-05 {
  width: 100%;
}

@media (min-width: 992px) {
  .column-block .column-05 {
    width: 38.5%;
  }
}

.column-block .column-05 .photo-1 {
  width: 100%;
  float: right;
}

@media (min-width: 768px) {
  .column-block .column-05 .photo-1 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .column-block .column-05 .photo-1 {
    width: 100%;
    float: none;
  }
}

.column-block .column-05 .photo-1 figure {
  padding-bottom: 100%;
}

@media (min-width: 992px) {
  .column-block .column-05 .photo-1 figure {
    padding-bottom: 70%;
  }
}

.column-block .column-05 .photo-2 {
  width: 100%;
  float: right;
}

@media (min-width: 768px) {
  .column-block .column-05 .photo-2 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .column-block .column-05 .photo-2 {
    width: 100%;
    float: none;
  }
}

.column-block .column-05 .photo-2 .text-block {
  width: 95%;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .column-block .column-05 .photo-2 .text-block {
    padding-top: 0rem;
  }
}

@media (min-width: 992px) {
  .column-block .column-05 .photo-2 .text-block {
    padding-top: 6rem;
  }
}

.column-block .column-06 {
  width: 50%;
}

@media (min-width: 992px) {
  .column-block .column-06 {
    width: 31%;
  }
}

.column-block .column-06 .photo-1 {
  width: 100%;
  float: right;
}

.column-block .column-06 .photo-1 figure {
  padding-bottom: 87.5%;
}

.column-block .column-06 .photo-2 {
  width: 100%;
  float: right;
}

.column-block .column-06 .photo-2 figure {
  padding-bottom: 124%;
}

@media (min-width: 992px) {
  .column-block .column-06 .photo-2 figure {
    padding-bottom: 120%;
  }
}

.column-block .column-07 {
  width: 50%;
}

@media (min-width: 992px) {
  .column-block .column-07 {
    width: 30.5%;
  }
}

.column-block .column-07 .photo-2 {
  width: 100%;
}

.column-block .column-07 .photo-2 figure {
  padding-bottom: 71%;
}

.column-block .column-07 .photo-1 {
  width: 100%;
}

.column-block .column-07 .photo-1 figure {
  padding-bottom: 72%;
}

.column-block .column-07 .photo-3 {
  width: 100%;
}

.column-block .column-07 .photo-3 figure {
  padding-bottom: 66%;
}

.page-banner {
  position: relative;
  padding-bottom: 35%;
  min-height: 400px;
}

.page-banner figure {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.page-banner figure:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: .7;
  z-index: 2;
}

.page-banner figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.page-banner .content {
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
  z-index: 3;
  text-align: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 100;
}

@media (min-width: 575px) {
  .page-banner .content {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  .page-banner .content {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  .page-banner .content {
    font-size: 1.4rem;
  }
}

@media (min-width: 1025px) {
  .page-banner .content {
    font-size: 1.6rem;
  }
}

.page-banner .content h2, .page-banner .content h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.3rem;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;
}

@media (min-width: 575px) {
  .page-banner .content h2, .page-banner .content h1 {
    font-size: 2.5rem;
    line-height: 2.7rem;
  }
}

@media (min-width: 768px) {
  .page-banner .content h2, .page-banner .content h1 {
    font-size: 2.8rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 992px) {
  .page-banner .content h2, .page-banner .content h1 {
    font-size: 3rem;
    line-height: 2.7rem;
  }
}

@media (min-width: 1025px) {
  .page-banner .content h2, .page-banner .content h1 {
    font-size: 3.5rem;
    line-height: 3rem;
  }
}

.page-banner .content p {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1rem;
}

@media (min-width: 575px) {
  .page-banner .content p {
    line-height: 1.1rem;
  }
}

@media (min-width: 768px) {
  .page-banner .content p {
    line-height: 1.2rem;
  }
}

@media (min-width: 992px) {
  .page-banner .content p {
    line-height: 1.4rem;
  }
}

@media (min-width: 1025px) {
  .page-banner .content p {
    line-height: 1.6rem;
  }
}

.page-block ul {
  list-style: none;
  padding: 0;
}

.page-block ul:last-child {
  margin-bottom: 0;
}

.page-block ul li {
  position: relative;
  padding-left: 2rem;
  padding-top: .5rem;
  padding-bottom: .55rem;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.page-block ul li:after {
  display: block;
  content: "";
  position: absolute;
  top: .7rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 1px solid #8aeb2b;
}

.page-block ul li:before {
  display: block;
  content: "";
  position: absolute;
  top: .95rem;
  left: .25rem;
  height: .5rem;
  width: .5rem;
  background-color: #8aeb2b;
  border-radius: 50%;
  z-index: 5;
}

.page-block .pre-title {
  margin: 0;
}

.page-block .title {
  text-transform: uppercase;
}

.page-block h2, .page-block h3 {
  color: #002a6e;
  margin-bottom: 2rem;
}

.page-block.block-welcome .text-block {
  max-width: 800px;
  margin: 0 auto;
}

.block-len .items {
  position: relative;
}

.block-len .items:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background-color: #f1f1f1;
  z-index: -1;
}

.block-len .item {
  position: relative;
  padding-bottom: 3rem;
}

.block-len .pre-title {
  font-size: 2rem;
  color: #002a6e;
  font-weight: 200;
  padding: 2rem 5%;
  position: relative;
  overflow: hidden;
}

@media (min-width: 992px) {
  .block-len .pre-title {
    overflow: visible;
    padding: 2rem 1rem;
    bottom: 34%;
    left: -14%;
    position: absolute;
    transform: rotate(-90deg);
    padding: 0;
  }
}

@media (min-width: 1025px) {
  .block-len .pre-title {
    bottom: 34.5%;
    left: -14%;
  }
}

@media (min-width: 1200px) {
  .block-len .pre-title {
    bottom: 28%;
    left: -11%;
  }
}

.block-len .pre-title:after {
  display: block;
  content: "";
  position: absolute;
  width: 200px;
  height: 2px;
  background-color: #002a6e;
  top: 50%;
  left: 260px;
}

@media (min-width: 992px) {
  .block-len .pre-title:after {
    left: 108%;
  }
}

.block-len figure {
  margin: 0;
  width: 90%;
  margin-left: 5%;
  position: relative;
  padding-bottom: 85%;
}

@media (min-width: 992px) {
  .block-len figure {
    margin-top: -5rem;
    width: 80%;
    margin-left: 15%;
  }
}

.block-len figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.block-len .text-block {
  padding: 1rem 5%;
}

@media (min-width: 992px) {
  .block-len .text-block {
    padding-right: 3rem;
    padding-left: 1rem;
  }
}

.block-len .text-block p:last-child {
  margin: 0;
}

.repeater-block01 {
  padding: 1rem 0;
}

@media (min-width: 575px) {
  .repeater-block01 {
    padding: 2rem 0;
  }
}

.repeater-block01:nth-child(2n) {
  flex-direction: row-reverse;
}

.repeater-block01 figure {
  margin: 0;
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}

.repeater-block01 figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.repeater-block01 .text-block {
  padding: 2rem 0;
}

.repeater-block01 .text-block p:last-child {
  margin: 0;
}

.repeater-block01 .text-block h2, .repeater-block01 .text-block h3, .repeater-block01 .text-block h4, .repeater-block01 .text-block h5 {
  text-transform: uppercase;
  font-weight: 600;
}

.repeater-block02 {
  padding: 1.5rem 0;
}

@media (min-width: 768px) {
  .repeater-block02 {
    padding: 2rem 0;
  }
}

@media (min-width: 992px) {
  .repeater-block02 {
    padding: 3rem 0;
  }
}

.repeater-block02:nth-child(2n) {
  flex-direction: row-reverse;
}

.repeater-block02:nth-child(2n) figure:after {
  right: unset;
  left: -1.5rem;
}

.repeater-block02 figure {
  margin: 0;
  width: 100%;
  padding-bottom: 80%;
  position: relative;
}

.repeater-block02 figure:after {
  display: block;
  content: "";
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  width: 40%;
  height: 40%;
  z-index: -1;
  background-color: #ebebeb;
}

.repeater-block02 figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.repeater-block02 .text-block {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .repeater-block02 .text-block {
    padding: 0;
  }
}

.repeater-block02 .text-block p:last-child {
  margin: 0;
}

.repeater-block02 .text-block h2, .repeater-block02 .text-block h3, .repeater-block02 .text-block h4, .repeater-block02 .text-block h5 {
  text-transform: uppercase;
  font-weight: 600;
}

.repeater-block-03 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.repeater-block-03 h2, .repeater-block-03 h3, .repeater-block-03 h4, .repeater-block-03 h5 {
  text-transform: uppercase;
  font-weight: 600;
}

.repeater-block-03 figure {
  margin: 0;
  width: 100%;
  padding-bottom: 60%;
  position: relative;
  margin-bottom: 2rem;
}

.repeater-block-03 figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.repeater-block-04 {
  margin: 1rem 0;
}

.repeater-block-04:nth-child(3) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(3) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(3) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(3) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(4) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(4) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(4) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(4) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(7) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(7) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(7) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(7) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(8) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(8) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(8) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(8) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(11) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(11) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(11) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(11) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(12) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(12) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(12) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(12) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(15) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(15) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(15) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(15) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(16) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(16) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(16) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(16) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(19) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(19) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(19) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(19) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04:nth-child(20) .items {
  flex-direction: row-reverse;
}

@media (min-width: 768px) {
  .repeater-block-04:nth-child(20) .items figure {
    margin-left: auto;
  }
  .repeater-block-04:nth-child(20) .items .text-block {
    padding-left: 2rem;
    padding-right: 0;
  }
  .repeater-block-04:nth-child(20) .items .title {
    left: auto;
    right: 0;
  }
}

.repeater-block-04 .items {
  background-color: #ebebeb;
}

.repeater-block-04 .item {
  position: relative;
}

.repeater-block-04 figure {
  margin: 0;
  padding-bottom: 60%;
  position: relative;
}

@media (min-width: 768px) {
  .repeater-block-04 figure {
    padding-bottom: 80%;
    width: 92%;
  }
}

@media (min-width: 992px) {
  .repeater-block-04 figure {
    padding-bottom: 170%;
  }
}

@media (min-width: 1025px) {
  .repeater-block-04 figure {
    padding-bottom: 170%;
  }
}

@media (min-width: 1100px) {
  .repeater-block-04 figure {
    padding-bottom: 165%;
  }
}

@media (min-width: 1281px) {
  .repeater-block-04 figure {
    padding-bottom: 120%;
  }
}

.repeater-block-04 figure:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000000;
  opacity: .5;
}

.repeater-block-04 figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.repeater-block-04 .title {
  position: absolute;
  padding: 1.5rem;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  z-index: 3;
  font-size: 1.3rem;
  margin: 0;
  line-height: 1.3;
  font-weight: 600;
}

@media (min-width: 768px) {
  .repeater-block-04 .title {
    width: 90%;
  }
}

.repeater-block-04 .text-block {
  padding: 1.5rem;
  font-size: .9rem;
}

@media (min-width: 768px) {
  .repeater-block-04 .text-block {
    padding: 1.5rem;
    padding-left: 0;
  }
}

.repeater-block-04 .text-block a {
  font-size: .8rem;
  font-weight: 500;
}

.map-block {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.map-block:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -1.5rem;
  left: -1.5rem;
  width: 70%;
  height: 60%;
  z-index: -1;
  background-color: #002a6e;
}

.map-block .map-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.map-block-footer {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
}

.map-block-footer:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -1.5rem;
  left: -1.5rem;
  width: 70%;
  height: 60%;
  z-index: -1;
  background-color: #002a6e;
}

.map-block-footer .map-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.block-map {
  padding-top: 3rem;
}

@media (min-width: 768px) {
  .block-map {
    padding-top: 4rem;
  }
}

@media (min-width: 992px) {
  .block-map {
    padding-left: 15%;
    padding-top: 0;
  }
}

.block-map .title {
  color: #002a6e;
  text-transform: uppercase;
  font-weight: 500;
}

.block-form {
  padding: 5% 0;
}

@media (min-width: 992px) {
  .block-form {
    padding: 0;
    padding-right: 17%;
  }
}

.block-icon-box {
  padding: 1.5rem;
  position: relative;
  z-index: 3;
}

.block-icon-box .title {
  font-size: 1rem;
  color: #002a6e;
  line-height: 1rem;
  font-weight: 600;
}

.block-icon-box .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.block-icon-box .icon svg {
  width: 100%;
  height: auto;
}

.block-icon-box .icon.contact {
  width: 2rem;
}

.block-icon-box .content {
  padding-top: 1.5rem;
  font-size: .9rem;
}

.block-icon-box .content p {
  margin-bottom: .5rem;
}

.block-icon-box .content p a {
  color: #5f5f5f;
  transition: .4s;
}

.block-icon-box .content p a:hover {
  color: #002a6e;
}

.block-icon-box-holder {
  position: relative;
  margin-bottom: 1rem;
  max-width: 400px;
}

.block-icon-box-holder:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  background-color: #ffffff;
  z-index: 0;
}

.widget_categories h2 {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
}

.widget_categories ul {
  list-style: none;
  padding: 0;
}

.widget_categories ul li {
  position: relative;
  padding-left: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  color: #000000;
  text-transform: uppercase;
  font-weight: 600;
}

.widget_categories ul li:after {
  display: block;
  content: "";
  position: absolute;
  top: .5rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 1px solid #8aeb2b;
}

.widget_categories ul li:before {
  display: block;
  content: "";
  position: absolute;
  top: .75rem;
  left: .25rem;
  height: .5rem;
  width: .5rem;
  background-color: #8aeb2b;
  border-radius: 50%;
  z-index: 5;
}

.recent-posts-widget-with-thumbnails {
  padding-top: 3rem;
}

.recent-posts-widget-with-thumbnails h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.recent-posts-widget-with-thumbnails a span {
  font-size: 1.1rem;
  font-weight: 600;
  color: #002a6e;
}

.block-featured {
  width: 100%;
  position: relative;
  padding-bottom: 60%;
  margin-bottom: 2rem;
}

.block-featured img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.block-single-blog .block-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.service-area-block ul {
  list-style: none;
  padding: 0;
}

.service-area-block ul li {
  position: relative;
  padding-left: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.service-area-block ul li:after {
  display: block;
  content: "";
  position: absolute;
  top: .5rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 1px solid #8aeb2b;
}

.service-area-block ul li:before {
  display: block;
  content: "";
  position: absolute;
  top: .75rem;
  left: .25rem;
  height: .5rem;
  width: .5rem;
  background-color: #8aeb2b;
  border-radius: 50%;
  z-index: 5;
}

.service-home-hold {
  overflow: hidden;
}

#menu-top-menu-1 .menu-item {
  position: relative;
}

#menu-top-menu-1 .menu-item .sub-menu {
  position: absolute;
  display: none;
  width: 350px;
  background-color: #ffffff;
  display: none !important;
  left: -27px;
  top: 45px;
  background-color: #002a6e;
  padding: 1rem !important;
}

#menu-top-menu-1 .menu-item .sub-menu li {
  display: block;
  padding: .3rem 1.5rem !important;
  line-height: 1.2;
  position: relative;
  z-index: 5;
  margin: 0 !important;
  position: relative;
  padding-bottom: 35px !important;
}

#menu-top-menu-1 .menu-item .sub-menu li.current-menu-item a {
  font-weight: 500;
}

#menu-top-menu-1 .menu-item .sub-menu li a {
  position: absolute;
  width: 82%;
  top: 50%;
  right: 0;
  transform: translatey(-50%);
  font-weight: 300;
  color: #ffffff;
  font-size: 1.1rem;
}

#menu-top-menu-1 .menu-item .sub-menu li:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  background-image: url(../images/weapon.png);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

#menu-top-menu-1 .menu-item .sub-menu li a {
  color: #ffffff;
}

#menu-top-menu-1 .menu-item .sub-menu li a:hover {
  color: #ffffff;
}

#menu-top-menu-1 .menu-item:hover .sub-menu {
  display: block !important;
}

.block-mobile-nav .sub-menu {
  display: none;
  padding-left: 1rem;
}

.block-mobile-nav .child-toggler {
  position: absolute;
  right: 0;
  padding: 8px;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 5;
  top: -9px;
  font-size: 1.5rem;
  color: #002a6e;
}

.block-mobile-nav .child-toggler.active {
  transform: rotate(90deg);
}

.block-mobile-nav .child-toggler a {
  color: #fff;
  height: 40px;
  line-height: 40px;
  display: block;
  -webkit-transition: .4s;
  transition: .4s;
  position: relative;
  z-index: 1;
}

.js-owl-testimonials-nav button {
  background-color: transparent;
  height: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #fff;
  margin: 0 .5rem;
  position: relative;
}

.js-owl-testimonials-nav button svg {
  position: absolute;
  height: 1rem;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.js-owl-testimonials-nav button.owl-prev svg {
  left: 45%;
}

.js-owl-testimonials-nav button.owl-next svg {
  left: 54%;
}

.btn-contact:hover svg {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: rotate(0deg);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: rotate(-5deg);
  }
  20%, 80% {
    transform: rotate(5deg);
  }
  30%, 50%, 70% {
    transform: rotate(-10deg);
  }
  40%, 60% {
    transform: rotate(10deg);
  }
}

.footer-social ul li a:hover i {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: rotate(0deg);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes downarrow {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.home-service .content a:hover img {
  animation: downarrow .4s;
}

.blog-box .btn-arrow:hover img {
  animation: downarrow .4s;
}

.block-welcome {
  overflow: hidden;
}

.is--modal {
  overflow: hidden;
}

.dsk-nav ul li {
  position: relative;
}

.dsk-nav ul li:after {
  display: block;
  content: "";
  position: absolute;
  height: 2rem;
  width: 1rem;
  background-image: url(../images/leaf.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  top: -280%;
  left: -8px;
  transition: .4s;
  z-index: -1;
}

.dsk-nav ul li.current-menu-item:after {
  top: -6px;
}

.dsk-nav ul li.current-menu-parent:after {
  top: -6px;
}

.dsk-nav ul li .sub-menu li:after {
  display: none;
}

.blog-box p {
  margin-bottom: .5rem;
}

.blog-box .btn-arrow {
  width: 180px;
  transition: .4s;
}

.blog-box .btn-arrow span {
  color: #002a6e;
}

.blog-box .btn-arrow img {
  width: 30px;
  margin-top: 3px;
}

.blog-box .btn-arrow:hover {
  width: 200px;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center .home-service {
  opacity: 1;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center figure {
  opacity: 1;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center .content .title {
  color: #ffffff;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center .content .title:after {
  background-color: #ffffff;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center .content a span {
  color: #ffffff;
}

.block-home-services .owl-carousel .owl-stage-outer .owl-stage div.center .content a img {
  filter: brightness(0) invert(1);
}

.block-home-gallery {
  overflow: hidden;
}

.about-gallery {
  overflow: hidden;
}

.service-area-gallery {
  margin-top: 1.5rem;
}

.service-area-gallery:after {
  display: block;
  content: "";
  clear: both;
}

.service-area-gallery figure {
  position: relative;
  padding-bottom: 50%;
  width: calc(40% - 6px);
  margin-left: 3px;
  margin-right: 3px;
  float: right;
  margin-bottom: 6px;
}

.service-area-gallery figure:nth-child(2) {
  width: calc(60% - 6px);
}

.service-area-gallery figure:nth-child(3) {
  width: calc(60% - 6px);
}

.service-area-gallery figure:nth-child(6) {
  width: calc(60% - 6px);
}

.service-area-gallery figure:nth-child(7) {
  width: calc(60% - 6px);
}

.service-area-gallery figure a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
}

.service-area-gallery figure img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

@media (min-width: 768px) {
  .mx-230 {
    max-width: 200px;
  }
}

.color-green {
  color: #016736;
}

.landing-caption {
  top: 50% !important;
  transform: translateY(-50%);
  text-align: center;
}

.block-landing-welcome figure {
  position: relative;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 120%;
}

@media (min-width: 992px) {
  .block-landing-welcome figure {
    padding-bottom: 140%;
  }
}

@media (min-width: 1100px) {
  .block-landing-welcome figure {
    padding-bottom: 120%;
  }
}

.block-landing-welcome figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 5;
}

.block-landing-welcome .items {
  position: relative;
  padding: 0 3rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 575px) {
  .block-landing-welcome .items {
    height: 1.1;
    padding: 0 4rem;
  }
}

@media (min-width: 768px) {
  .block-landing-welcome .items {
    padding: 0 4.5rem;
  }
}

@media (min-width: 992px) {
  .block-landing-welcome .items {
    margin-bottom: -3rem;
    padding: 0 5rem;
  }
}

.block-landing-welcome .owl-dots {
  display: none;
}

@media (min-width: 992px) {
  .block-landing-welcome .text-block {
    padding-left: 3rem;
  }
}

.block-landing-welcome .text-block .title {
  font-weight: 700;
  color: #002a6e;
  font-size: 2.3rem;
}

.block-landing-welcome .text-block .pre-title {
  font-size: 1.3rem;
  height: 1.3rem;
  color: #000000;
  margin: 0;
}

.block-landing-welcome .js-owl-welcome-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.block-landing-welcome .js-owl-welcome-nav button {
  border: none;
  height: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 50%;
  background-color: #002a6e;
  margin: 0 .5rem;
  position: relative;
}

@media (min-width: 575px) {
  .block-landing-welcome .js-owl-welcome-nav button {
    height: 2.2rem;
    width: 2.2rem;
  }
}

@media (min-width: 768px) {
  .block-landing-welcome .js-owl-welcome-nav button {
    height: 2.6rem;
    width: 2.6rem;
  }
}

@media (min-width: 992px) {
  .block-landing-welcome .js-owl-welcome-nav button {
    height: 3rem;
    width: 3rem;
  }
}

.block-landing-welcome .js-owl-welcome-nav button svg {
  position: absolute;
  height: 1rem;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
}

@media (min-width: 575px) {
  .block-landing-welcome .js-owl-welcome-nav button svg {
    height: 1.1;
  }
}

@media (min-width: 768px) {
  .block-landing-welcome .js-owl-welcome-nav button svg {
    height: 1.3rem;
  }
}

@media (min-width: 992px) {
  .block-landing-welcome .js-owl-welcome-nav button svg {
    height: 1.5rem;
  }
}

.block-landing-welcome .js-owl-welcome-nav button.owl-prev svg {
  left: 46%;
}

.block-landing-welcome .js-owl-welcome-nav button.owl-next svg {
  left: 54%;
}

.block-ser-area-listing {
  background-color: #f3f7fa;
  position: relative;
}

.block-ser-area-listing:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  height: 50px;
  background-image: url(../images/land-bk-top.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  z-index: -1;
}

@media (min-width: 768px) {
  .block-ser-area-listing:after {
    height: 100px;
  }
}

.block-ser-area-listing:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-image: url(../images/land-bk-bottom.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 50px;
  z-index: -1;
}

@media (min-width: 768px) {
  .block-ser-area-listing:before {
    height: 100px;
  }
}

.landing-banner-bottom-box {
  background-color: #002a6e;
  padding: 2rem;
}

.landing-banner-bottom-box h4 {
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  margin: 0;
  font-size: 1rem;
}

.landing-banner-bottom-box-holder {
  margin-top: 1rem;
  position: relative;
}

@media (min-width: 992px) {
  .landing-banner-bottom-box-holder {
    margin-top: 1.5rem;
  }
}

@media (min-width: 992px) {
  .landing-banner-bottom-box-holder {
    margin-top: 0;
    transform: translatey(3rem);
  }
}

.landing-banner-bottom-box-holder:after {
  position: absolute;
  top: 0;
  height: 100%;
  left: 10px;
  right: 10px;
  z-index: -1;
  display: block;
  content: "";
  background-color: #002a6e;
}

@media (min-width: 992px) {
  .landing-banner {
    height: calc( 100vh - 3rem) !important;
  }
}

.landing-listing li {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

@media (min-width: 600px) {
  .landing-listing li {
    width: 32%;
  }
}

@media (min-width: 992px) {
  .landing-listing li {
    width: 24%;
  }
}

@media (min-width: 1281px) {
  .landing-listing li {
    width: 19%;
  }
}

.landing-inner-listing li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

@media (min-width: 575px) {
  .landing-inner-listing li {
    width: 49%;
  }
}

@media (min-width: 992px) {
  .landing-inner-listing li {
    width: 33%;
  }
}

@media (min-width: 1281px) {
  .landing-inner-listing li {
    width: 24%;
  }
}

.block-ser-area-listing-inner {
  background-color: #f3f7fa;
}

#menu-top-menu-1 .menu-item-26 .sub-menu {
  position: fixed;
  top: 6.5rem;
  left: 10vw;
  width: 80vw;
  padding: 2rem !important;
  align-items: start;
  flex-wrap: wrap;
  background-color: #002a6e;
  z-index: 1;
  padding-top: 0 !important;
}

#menu-top-menu-1 .menu-item-26 .sub-menu:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: transparent;
}

#menu-top-menu-1 .menu-item-26 .sub-menu li {
  width: 33%;
  margin: .3rem 0;
  padding: .8rem !important;
  padding-bottom: 5% !important;
  position: relative;
}

#menu-top-menu-1 .menu-item-26 .sub-menu li.current-menu-item a {
  font-weight: 500;
}

#menu-top-menu-1 .menu-item-26 .sub-menu li a {
  position: absolute;
  width: 84%;
  top: 50%;
  right: 0;
  transform: translatey(-50%);
  font-weight: 300;
  color: #ffffff;
  font-size: 1.1rem;
}

#menu-top-menu-1 .menu-item-26 .sub-menu li:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  background-image: url(../images/weapon.png);
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
}

#menu-top-menu-1 .menu-item-26 .sub-menu:before {
  left: 44% !important;
}

#menu-top-menu-1 .menu-item-26 .sub-menu:after {
  height: 50px !important;
  top: -40px !important;
}

#menu-top-menu-1 .menu-item-26:hover .sub-menu {
  display: flex !important;
}

.stars {
  margin-bottom: 1.5rem;
}

.stars svg {
  fill: #ffffff;
  display: inline-block;
  vertical-align: middle;
  height: 2rem;
  width: 2rem;
}

.color-theme {
  color: #002a6e;
}

.owl-logos a {
  display: block;
  width: 120px;
  height: 80px;
  position: relative;
  margin: 0 auto;
}

.owl-logos a img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}

.page-service-area h1, .page-service-area h2, .page-service-area h3, .page-service-area h4, .page-service-area h5, .page-service-area h6 {
  text-transform: uppercase;
}

.contact-blocks {
  background-color: #f3f7fa;
}

#return-to-top {
  z-index: 999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #002a6e;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: #000000;
}

#return-to-top:hover i {
  color: #fff;
}

.block-footer .title {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.block-footer .menu-title {
  margin-bottom: 1rem;
}

.block-video {
  display: block;
  position: relative;
  z-index: 0;
  background-color: #002a6e;
  padding: 1px;
}

.block-video .video-block {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.block-video .video-block::before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: transparent;
}

.block-video .icon-block {
  display: block;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}

.block-video .icon-block svg {
  display: block;
  width: 100%;
  height: 100%;
}

.block-video iframe,
.block-video object,
.block-video embed,
.block-video img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}

.block-page-videos .btn, .block-page-videos body div.wpforms-container-full .wpforms-form button[type=submit], body div.wpforms-container-full .wpforms-form .block-page-videos button[type=submit] {
  margin: 0 5px;
}

.block-page-videos .items {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.block-page-videos .item {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  padding: 0 10px 10px;
}

@media (min-width: 575px) {
  .block-page-videos .item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .block-page-videos .item {
    width: 33.333333%;
  }
}

.widget_search .widget-content {
  position: relative;
  z-index: 0;
}

.widget_search .widget-content::after {
  font-family: 'FontAwesome';
  content: "\f002";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #002a6e;
}

.widget_search .widget-title {
  display: none;
}

.widget_search label {
  display: block;
  margin: 0;
}

.widget_search input[type="text"] {
  width: 100%;
  height: 3rem;
  border: solid 1px #002a6e;
  background-color: transparent;
  padding-left: 1rem;
  padding-right: 3rem;
}

.widget_search input[type="submit"] {
  width: 3rem;
  height: 100%;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  text-indent: -9999999px;
}

.logged-in #menu-top-menu-1 .menu-item-26 .sub-menu {
  top: 8.5rem;
}

.menu-item-548 .sub-menu:after {
  display: block !important;
  background-color: transparent;
  content: "";
  height: 30px;
  top: -20px;
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100%;
}

.g--modal .grecaptcha-badge {
  position: relative !important;
  left: 0 !important;
  bottom: 0rem !important;
}

@media (min-width: 575px) {
  .g--modal .grecaptcha-badge {
    position: absolute !important;
    left: 0 !important;
    bottom: -1rem !important;
  }
}

@media (min-width: 575px) {
  .g--modal .wpforms-submit-container {
    text-align: right;
  }
}

#myModal {
  background-color: #002a6e5c;
  z-index: 1000;
  overflow: scroll;
}

.modal-info {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translatex(-50%);
  padding: 3rem 2rem 2rem;
  border: 1px solid #002a6e;
  color: #000000;
  background-color: #ffffff;
  z-index: 1001;
  max-width: 600px;
  width: 95%;
  margin-bottom: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

@media (min-width: 575px) {
  .modal-info {
    padding: 4rem 3rem 3rem;
  }
}

@media (min-width: 768px) {
  .modal-info {
    padding: 5rem 4rem 4rem;
    transform: translate(-50%, -50%);
    top: 50%;
    margin-bottom: 0;
  }
}

.modal-info h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #002a6e;
  position: relative;
  padding-bottom: 1rem;
}

.modal-info h2:after {
  display: block;
  content: "";
  width: 200px;
  height: 1px;
  background-color: #002a6e;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
}

.modal-info ul {
  list-style: none;
  padding: 0;
}

.modal-info ul li {
  position: relative;
  padding-left: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.modal-info ul li:after {
  display: block;
  content: "";
  position: absolute;
  top: .5rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 1px solid #8aeb2b;
}

.modal-info ul li:before {
  display: block;
  content: "";
  position: absolute;
  top: .75rem;
  left: .25rem;
  height: .5rem;
  width: .5rem;
  background-color: #8aeb2b;
  border-radius: 50%;
  z-index: 5;
}

.modal-info .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-info .close-btn svg {
  height: 2rem;
  width: 2rem;
  fill: #8aeb2b;
}

.modal-info .close-btn:hover {
  cursor: pointer;
}

.gap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 575px) {
  .gap {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .gap {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.gap-up {
  padding-top: 2rem;
}

@media (min-width: 575px) {
  .gap-up {
    padding-top: 3rem;
  }
}

@media (min-width: 768px) {
  .gap-up {
    padding-top: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap-up {
    padding-top: 6rem;
  }
}

.gap-down {
  padding-bottom: 2rem;
}

@media (min-width: 575px) {
  .gap-down {
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) {
  .gap-down {
    padding-bottom: 3.5rem;
  }
}

@media (min-width: 992px) {
  .gap-down {
    padding-bottom: 6rem;
  }
}

.gap-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 575px) {
  .gap-sm {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .gap-sm {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }
}

@media (min-width: 992px) {
  .gap-sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.gap-sm-up {
  padding-top: 1rem;
}

@media (min-width: 575px) {
  .gap-sm-up {
    padding-top: 1.5rem;
  }
}

@media (min-width: 768px) {
  .gap-sm-up {
    padding-top: 1.7rem;
  }
}

@media (min-width: 992px) {
  .gap-sm-up {
    padding-top: 2rem;
  }
}

.gap-sm-down {
  padding-bottom: 1rem;
}

@media (min-width: 575px) {
  .gap-sm-down {
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .gap-sm-down {
    padding-bottom: 1.7rem;
  }
}

@media (min-width: 992px) {
  .gap-sm-down {
    padding-bottom: 2rem;
  }
}
