@import 'variables';

.field-block{
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  label{
    font-weight: 400;
    color: $font;
  }
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), textarea, select{
    width: 100%;
    border: solid 1px #7e7e7e;
    border-radius: 0;
    padding: .5rem 1rem;
    height: 3rem;
    font-size: .9rem;
    font-weight: 400;
    position: relative;
    z-index: 1;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: .4s;
    color: $font!important;

    &:focus{
      border-color: $theme;
    }
  }
  input[type="file"]{
    padding-top: .7rem !important;
    height: auto !important;
  }
  select{
    background-image: url("../images/icon-sort.svg");
    background-position: 98% center;
    background-repeat: no-repeat;
    background-size: .7rem;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  textarea,
  textarea.wpforms-field-large{
    height: 6rem;
    padding-top: 1rem;
    @include vpSize(xmd){
      height: 8rem;
    }
  }
}
.field-check{
  li{
    position: relative;
    margin-bottom: 1rem !important;

    input[type=radio],
    input[type=checkbox]{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
      appearance: none;
      border: none;
      height: 20px;
      width: 20px;
    }
    label{
      padding-left: 25px;

      &::before,
      &::after{
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: .4s;
      }
      &::before{
        border: solid 1px $theme;
      }
      &::after{
        top: 3px;
        left: 3px;
        z-index: 2;
        width: 14px;
        height: 14px;
        background-color: $theme;
        transform: scale(0);
        opacity: 0;
      }

      div{
        display: inline-block;
        padding-left: .5rem;
        margin-top: -4px;

        b{
          font-size: 1rem;
        }

        span{
          font-size: .9rem;
        }
      }
    }
    input[type=radio]:checked + label,
    input[type=checkbox]:checked + label{
      &::after{
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.g--overlay {
    background-color: rgba(255, 255, 255, 0.7);
    display: none;
    z-index: 1004
}

.g--modal,
.g--overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.g--modal {
    z-index: 2000;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    visibility: hidden;
    -webkit-transition: all .4s;
    transition: all .4s;
    text-align: center;
    display: none;
}

.g--modal.active {
    display: block;
    opacity: 1;
    visibility: visible
}

.g--modal.active .content {
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1)
}

.g--modal .content {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 600px;
    max-width: 90%;
    margin: 5% auto;
    background-color: #fff;
    -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-400px) scale(0.7);
    transform: translateY(-400px) scale(0.7);
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    -webkit-transition: all .4s;
    transition: all .4s;
    text-align: left
}

.g--modal .content ._close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    width: 30px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all 1s;
    transition: all 1s
}

.g--modal .content ._close::after,
.g--modal .content ._close::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
    content: '';
    background-color: #000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .4s;
    transition: all .4s
}

.g--modal .content ._close:before {
    width: 90%;
    height: 2px
}

.g--modal .content ._close:after {
    width: 2px;
    height: 90%
}

.g--modal .content .close:hover:after,
.g--modal .content .close:hover:before {
    opacity: .8
}

.g--modal .content ._close:hover:before {
    width: 80%
}

.g--modal .content ._close:hover:after {
    height: 80%
}

.g--modal .data {
    padding: 60px 20px 25px;
    @include vpSize(x7){
       padding: 50px 40px;
    }
}

.g--modal header {
    margin-bottom: 30px
}

.g--modal h2 {
    text-align: center;
    margin-bottom: 10px
}

.g--modal h2,
.g--modal p {
    font-weight: 300
    font-size2rem;
}
.g--modal .wpforms-submit-container{
  text-align: center;
}
#wpforms-16-field_9{
  border: 1px solid #7e7e7e!important;
  color: $font!important;
}
#wpforms-551-field_9{
  border: 1px solid #7e7e7e!important;
  color: $font!important;
}