@import 'variables';
.block-pagination{
  margin-top: 2rem;
  a,
  span {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border: solid 1px $theme;
    margin: 0 2px;
    padding: 0 .5rem;
    font-size: .9rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: $font;
  }
  a{
    cursor: pointer;
  }
  .current{
    border-color: $theme;
    background-color: $theme;
    color: $white;
  }
}
